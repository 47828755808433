
export default function getCpamFromCP(cp: string): any[] {
    let cpam
    let cpams
    let dep = parseInt(cp) > 96000 ? cp.slice(0, 3) : cp.slice(0, 2)
    let bdi = cp.slice(2, 5)

    // Get CPAM from CP.
    cpam = cpamCP.find(_ => _.cp === cp)
    if (cpam) return [cpam]

    // Get CPAM from Departement.
    cpams = cpamDep.filter(_ => _.dep === dep)
    if (cpams.length === 1) return cpams

    // Get CPAM from BDI.
    cpams = cpams.map(_ => { if (_.bdis.find(v => v === bdi)) return _; }).filter(_ => _ !== undefined)
    if (cpams.length === 1) return cpams

    return cpams
}


export const cpam = [
    {
        value: '011',
        label: "CPAM Bourg-en-Bresse",
        regime: '01'
    },
    {
        value: '021',
        label: "CPAM de l'Aisne",
        regime: '01'
    },
    {
        value: '022',
        label: "CPAM Saint-Quentin",
        regime: '01'
    },
    {
        value: '031',
        label: "CPAM Moulins",
        regime: '01'
    },
    {
        value: '041',
        label: "CPAM Dignes",
        regime: '01'
    },
    {
        value: '051',
        label: "CPAM Gap",
        regime: '01'
    },
    {
        value: '061',
        label: "CPAM Nice",
        regime: '01'
    },
    {
        value: '071',
        label: "CPAM Annonay",
        regime: '01'
    },
    {
        value: '072',
        label: "CPAM de l'Ardèche",
        regime: '01'
    },
    {
        value: '081',
        label: "CPAM Charleville-Mézières",
        regime: '01'
    },
    {
        value: '091',
        label: "CPAM Foix",
        regime: '01'
    },
    {
        value: '101',
        label: "CPAM Troyes",
        regime: '01'
    },
    {
        value: '111',
        label: "CPAM Carcassonne",
        regime: '01'
    },
    {
        value: '121',
        label: "CPAM Rodez",
        regime: '01'
    },
    {
        value: '131',
        label: "CPAM Marseille",
        regime: '01'
    },
    {
        value: '141',
        label: "CPAM Caen",
        regime: '01'
    },
    {
        value: '151',
        label: "CPAM Aurillac",
        regime: '01'
    },
    {
        value: '161',
        label: "CPAM Angoulême",
        regime: '01'
    },
    {
        value: '171',
        label: "CPAM La Rochelle",
        regime: '01'
    },
    {
        value: '181',
        label: "CPAM Bourges",
        regime: '01'
    },
    {
        value: '191',
        label: "CPAM Tulle",
        regime: '01'
    },
    {
        value: '201',
        label: "CPAM Ajaccio",
        regime: '01'
    },
    {
        value: '202',
        label: "CPAM Bastia",
        regime: '01'
    },
    {
        value: '211',
        label: "CPAM Dijon",
        regime: '01'
    },
    {
        value: '221',
        label: "CPAM Saint-Brieuc",
        regime: '01'
    },
    {
        value: '231',
        label: "CPAM Guéret",
        regime: '01'
    },
    {
        value: '241',
        label: "CPAM Dordogne",
        regime: '01'
    },
    {
        value: '251',
        label: "CPAM du Doubs",
        regime: '01'
    },
    {
        value: '252',
        label: "CPAM Montbelliard",
        regime: '01'
    },
    {
        value: '261',
        label: "CPAM Valence",
        regime: '01'
    },
    {
        value: '271',
        label: "CPAM Evreux",
        regime: '01'
    },
    {
        value: '281',
        label: "CPAM Chartres",
        regime: '01'
    },
    {
        value: '291',
        label: "CPAM du Finistère",
        regime: '01'
    },
    {
        value: '292',
        label: "CPAM Quimper",
        regime: '01'
    },
    {
        value: '301',
        label: "CPAM Nîmes",
        regime: '01'
    },
    {
        value: '311',
        label: "CPAM Toulouse",
        regime: '01'
    },
    {
        value: '321',
        label: "CPAM Auch",
        regime: '01'
    },
    {
        value: '331',
        label: "CPAM Bordeaux",
        regime: '01'
    },
    {
        value: '341',
        label: "CPAM Beziers",
        regime: '01'
    },
    {
        value: '342',
        label: "CPAM de l'Hérault",
        regime: '01'
    },
    {
        value: '351',
        label: "CPAM Rennes",
        regime: '01'
    },
    {
        value: '361',
        label: "CPAM Châteauroux",
        regime: '01'
    },
    {
        value: '371',
        label: "CPAM Tours",
        regime: '01'
    },
    {
        value: '381',
        label: "CPAM de l'Isère",
        regime: '01'
    },
    {
        value: '382',
        label: "CPAM Vienne",
        regime: '01'
    },
    {
        value: '391',
        label: "CPAM Lons-le-Saunier",
        regime: '01'
    },
    {
        value: '401',
        label: "CPAM Mont-de-Marsan",
        regime: '01'
    },
    {
        value: '411',
        label: "CPAM Blois",
        regime: '01'
    },
    {
        value: '421',
        label: "CPAM Roanne",
        regime: '01'
    },
    {
        value: '422',
        label: "CPAM de la Loire",
        regime: '01'
    },
    {
        value: '431',
        label: "CPAM Le Puy",
        regime: '01'
    },
    {
        value: '441',
        label: "CPAM de Loire-Atlantique",
        regime: '01'
    },
    {
        value: '442',
        label: "CPAM Saint-Nazaire",
        regime: '01'
    },
    {
        value: '451',
        label: "CPAM Orléans",
        regime: '01'
    },
    {
        value: '461',
        label: "CPAM Cahors",
        regime: '01'
    },
    {
        value: '471',
        label: "CPAM Agen",
        regime: '01'
    },
    {
        value: '481',
        label: "CPAM Mende",
        regime: '01'
    },
    {
        value: '491',
        label: "CPAM Maine-et-Loire",
        regime: '01'
    },
    {
        value: '492',
        label: "CPAM Cholet",
        regime: '01'
    },
    {
        value: '501',
        label: "CPAM Saint-Lô",
        regime: '01'
    },
    {
        value: '511',
        label: "CPAM Reims",
        regime: '01'
    },
    {
        value: '521',
        label: "CPAM Chaumont",
        regime: '01'
    },
    {
        value: '531',
        label: "CPAM Laval",
        regime: '01'
    },
    {
        value: '541',
        label: "CPAM Longwy",
        regime: '01'
    },
    {
        value: '542',
        label: "CPAM de Meurthe-et-Moselle",
        regime: '01'
    },
    {
        value: '551',
        label: "CPAM Bar-le-Duc",
        regime: '01'
    },
    {
        value: '561',
        label: "CPAM Vannes",
        regime: '01'
    },
    {
        value: '571',
        label: "CPAM de Moselle",
        regime: '01'
    },
    {
        value: '572',
        label: "CPAM Sarreguemines",
        regime: '01'
    },
    {
        value: '573',
        label: "CPAM Thionville",
        regime: '01'
    },
    {
        value: '581',
        label: "CPAM Nevers",
        regime: '01'
    },
    {
        value: '591',
        label: "CPAM Armentières",
        regime: '01'
    },
    {
        value: '592',
        label: "CPAM Cambrai",
        regime: '01'
    },
    {
        value: '593',
        label: "CPAM Douai",
        regime: '01'
    },
    {
        value: '594',
        label: "CPAM Flandres-Dunkerque-Armentières",
        regime: '01'
    },
    {
        value: '595',
        label: "CPAM Lille-Douai",
        regime: '01'
    },
    {
        value: '596',
        label: "CPAM Maubeuge",
        regime: '01'
    },
    {
        value: '597',
        label: "CPAM Roubaix-Tourcoing",
        regime: '01'
    },
    {
        value: '598',
        label: "CPAM Tourcoing",
        regime: '01'
    },
    {
        value: '599',
        label: "CPAM du Hainaut",
        regime: '01'
    },
    {
        value: '601',
        label: "CPAM de l'Oise",
        regime: '01'
    },
    {
        value: '602',
        label: "CPAM Creil",
        regime: '01'
    },
    {
        value: '611',
        label: "CPAM Alençon",
        regime: '01'
    },
    {
        value: '621',
        label: "CPAM Arras",
        regime: '01'
    },
    {
        value: '622',
        label: "CPAM Boulogne-sur-Mer",
        regime: '01'
    },
    {
        value: '623',
        label: "CPAM de la Côte d'Opale",
        regime: '01'
    },
    {
        value: '624',
        label: "CPAM de l'Artois",
        regime: '01'
    },
    {
        value: '631',
        label: "CPAM Clermont-Ferrand",
        regime: '01'
    },
    {
        value: '641',
        label: "CPAM Bayonne",
        regime: '01'
    },
    {
        value: '642',
        label: "CPAM Pau",
        regime: '01'
    },
    {
        value: '651',
        label: "CPAM Tarbes",
        regime: '01'
    },
    {
        value: '661',
        label: "CPAM Perpignan",
        regime: '01'
    },
    {
        value: '671',
        label: "CPAM Haguenau",
        regime: '01'
    },
    {
        value: '672',
        label: "CPAM Selestat",
        regime: '01'
    },
    {
        value: '673',
        label: "CPAM Bas-Rhin",
        regime: '01'
    },
    {
        value: '681',
        label: "CPAM Colmar",
        regime: '01'
    },
    {
        value: '682',
        label: "CPAM du Haut-Rhin",
        regime: '01'
    },
    {
        value: '691',
        label: "CPAM du Rhône",
        regime: '01'
    },
    {
        value: '692',
        label: "CPAM Villefranche",
        regime: '01'
    },
    {
        value: '701',
        label: "CPAM Vesoul",
        regime: '01'
    },
    {
        value: '711',
        label: "CPAM Mâcon",
        regime: '01'
    },
    {
        value: '721',
        label: "CPAM Le Mans",
        regime: '01'
    },
    {
        value: '731',
        label: "CPAM Chambéry",
        regime: '01'
    },
    {
        value: '741',
        label: "CPAM Annecy",
        regime: '01'
    },
    {
        value: '751',
        label: "CPAM Paris",
        regime: '01'
    },
    {
        value: '759',
        label: "CPAM Ile-de-France",
        regime: '01'
    },
    {
        value: '761',
        label: "CPAM Dieppe",
        regime: '01'
    },
    {
        value: '762',
        label: "CPAM Elbeuf",
        regime: '01'
    },
    {
        value: '763',
        label: "CPAM Le Havre",
        regime: '01'
    },
    {
        value: '764',
        label: "CPAM Rouen-Elbeuf-Dieppe",
        regime: '01'
    },
    {
        value: '771',
        label: "CPAM Melun",
        regime: '01'
    },
    {
        value: '781',
        label: "CPAM Versailles",
        regime: '01'
    },
    {
        value: '791',
        label: "CPAM Niort",
        regime: '01'
    },
    {
        value: '801',
        label: "CPAM Amiens",
        regime: '01'
    },
    {
        value: '811',
        label: "CPAM Albi",
        regime: '01'
    },
    {
        value: '821',
        label: "CPAM Montauban",
        regime: '01'
    },
    {
        value: '831',
        label: "CPAM Toulon",
        regime: '01'
    },
    {
        value: '841',
        label: "CPAM Avignon",
        regime: '01'
    },
    {
        value: '851',
        label: "CPAM La Roche-sur-Yon",
        regime: '01'
    },
    {
        value: '861',
        label: "CPAM Poitiers",
        regime: '01'
    },
    {
        value: '871',
        label: "CPAM Limoges",
        regime: '01'
    },
    {
        value: '881',
        label: "CPAM Epinal",
        regime: '01'
    },
    {
        value: '891',
        label: "CPAM Auxerre",
        regime: '01'
    },
    {
        value: '901',
        label: "CPAM Belfort",
        regime: '01'
    },
    {
        value: '911',
        label: "CPAM Evry",
        regime: '01'
    },
    {
        value: '921',
        label: "CPAM Nanterre",
        regime: '01'
    },
    {
        value: '931',
        label: "CPAM Bobiny",
        regime: '01'
    },
    {
        value: '941',
        label: "CPAM Créteil",
        regime: '01'
    },
    {
        value: '951',
        label: "CPAM Cergy",
        regime: '01'
    },
    {
        value: '971',
        label: "CPAM Guadeloupe",
        regime: '01'
    },
    {
        value: '972',
        label: "CPAM Martinique",
        regime: '01'
    },
    {
        value: '973',
        label: "CPAM Guyane",
        regime: '01'
    },
    {
        value: '974',
        label: "CPAM Réunion",
        regime: '01'
    },
    {
        value: '976',
        label: "CPAM Mayotte",
        regime: '01'
    }
]


export const cpamCP = [
    {
        cpamId: "641",
        cp: "40220"
    },
    {
        cpamId: "641",
        cp: "40390"
    },
    {
        cpamId: "641",
        cp: "40440"
    },
]


export const cpamDep = [
    {
        cpamId: "011",
        dep: "01",
        bdis: []
    },
    {
        cpamId: "021",
        dep: "02",
        bdis: []
    },
    {
        cpamId: "031",
        dep: "03",
        bdis: []
    },
    {
        cpamId: "041",
        dep: "04",
        bdis: []
    },
    {
        cpamId: "051",
        dep: "05",
        bdis: []
    },
    {
        cpamId: "061",
        dep: "06",
        bdis: []
    },
    {
        cpamId: "071",
        dep: "07",
        bdis: []
    },
    {
        cpamId: "081",
        dep: "08",
        bdis: []
    },
    {
        cpamId: "091",
        dep: "09",
        bdis: []
    },
    {
        cpamId: "101",
        dep: "10",
        bdis: []
    },
    {
        cpamId: "111",
        dep: "11",
        bdis: []
    },
    {
        cpamId: "121",
        dep: "12",
        bdis: []
    },
    {
        cpamId: "131",
        dep: "13",
        bdis: []
    },
    {
        cpamId: "141",
        dep: "14",
        bdis: []
    },
    {
        cpamId: "151",
        dep: "15",
        bdis: []
    },
    {
        cpamId: "161",
        dep: "16",
        bdis: []
    },
    {
        cpamId: "171",
        dep: "17",
        bdis: []
    },
    {
        cpamId: "181",
        dep: "18",
        bdis: []
    },
    {
        cpamId: "191",
        dep: "19",
        bdis: []
    },
    {
        cpamId: "201",
        dep: "20",
        bdis: [
            "000",
            "090",
            "100",
            "107",
            "110",
            "111",
            "112",
            "113",
            "114",
            "115",
            "116",
            "117",
            "118",
            "119",
            "120",
            "121",
            "122",
            "123",
            "124",
            "125",
            "126",
            "127",
            "128",
            "129",
            "130",
            "131",
            "132",
            "133",
            "134",
            "135",
            "136",
            "137",
            "138",
            "139",
            "140",
            "141",
            "142",
            "143",
            "144",
            "145",
            "146",
            "147",
            "148",
            "150",
            "151",
            "152",
            "153",
            "157",
            "160",
            "161",
            "162",
            "163",
            "164",
            "165",
            "166",
            "167",
            "168",
            "169",
            "170",
            "171",
            "172",
            "173",
            "184",
            "190",
            "192",
            "195",
            "301"
        ]
    },
    {
        cpamId: "202",
        dep: "20",
        bdis: [
            "200",
            "211",
            "212",
            "213",
            "214",
            "215",
            "216",
            "217",
            "218",
            "219",
            "220",
            "221",
            "222",
            "224",
            "225",
            "226",
            "227",
            "228",
            "229",
            "230",
            "231",
            "232",
            "233",
            "234",
            "235",
            "236",
            "237",
            "238",
            "239",
            "240",
            "242",
            "243",
            "244",
            "245",
            "246",
            "247",
            "248",
            "250",
            "251",
            "252",
            "253",
            "256",
            "258",
            "259",
            "260",
            "263",
            "264",
            "266",
            "267",
            "268",
            "269",
            "270",
            "272",
            "273",
            "275",
            "276",
            "279",
            "290",
            "296"
        ]
    },
    {
        cpamId: "211",
        dep: "21",
        bdis: []
    },
    {
        cpamId: "221",
        dep: "22",
        bdis: []
    },
    {
        cpamId: "231",
        dep: "23",
        bdis: []
    },
    {
        cpamId: "241",
        dep: "24",
        bdis: []
    },
    {
        cpamId: "251",
        dep: "25",
        bdis: []
    },
    {
        cpamId: "252",
        dep: "25",
        bdis: []
    },
    {
        cpamId: "261",
        dep: "26",
        bdis: []
    },
    {
        cpamId: "271",
        dep: "27",
        bdis: []
    },
    {
        cpamId: "281",
        dep: "28",
        bdis: []
    },
    {
        cpamId: "291",
        dep: "29",
        bdis: []
    },
    {
        cpamId: "292",
        dep: "29",
        bdis: []
    },
    {
        cpamId: "301",
        dep: "30",
        bdis: []
    },
    {
        cpamId: "311",
        dep: "31",
        bdis: []
    },
    {
        cpamId: "321",
        dep: "32",
        bdis: []
    },
    {
        cpamId: "331",
        dep: "33",
        bdis: []
    },
    {
        cpamId: "341",
        dep: "34",
        bdis: []
    },
    {
        cpamId: "342",
        dep: "34",
        bdis: []
    },
    {
        cpamId: "351",
        dep: "35",
        bdis: []
    },
    {
        cpamId: "361",
        dep: "36",
        bdis: []
    },
    {
        cpamId: "371",
        dep: "37",
        bdis: []
    },
    {
        cpamId: "381",
        dep: "38",
        bdis: []
    },
    {
        cpamId: "382",
        dep: "38",
        bdis: []
    },
    {
        cpamId: "391",
        dep: "39",
        bdis: []
    },
    {
        cpamId: "401",
        dep: "40",
        bdis: []
    },
    {
        cpamId: "411",
        dep: "41",
        bdis: []
    },
    {
        cpamId: "421",
        dep: "42",
        bdis: []
    },
    {
        cpamId: "422",
        dep: "42",
        bdis: []
    },
    {
        cpamId: "431",
        dep: "43",
        bdis: []
    },
    {
        cpamId: "441",
        dep: "44",
        bdis: []
    },
    {
        cpamId: "442",
        dep: "44",
        bdis: []
    },
    {
        cpamId: "451",
        dep: "45",
        bdis: []
    },
    {
        cpamId: "461",
        dep: "46",
        bdis: []
    },
    {
        cpamId: "471",
        dep: "47",
        bdis: []
    },
    {
        cpamId: "481",
        dep: "48",
        bdis: []
    },
    {
        cpamId: "491",
        dep: "49",
        bdis: []
    },
    {
        cpamId: "492",
        dep: "49",
        bdis: []
    },
    {
        cpamId: "501",
        dep: "50",
        bdis: []
    },
    {
        cpamId: "511",
        dep: "51",
        bdis: []
    },
    {
        cpamId: "521",
        dep: "52",
        bdis: []
    },
    {
        cpamId: "531",
        dep: "53",
        bdis: []
    },
    {
        cpamId: "541",
        dep: "54",
        bdis: []
    },
    {
        cpamId: "542",
        dep: "54",
        bdis: []
    },
    {
        cpamId: "551",
        dep: "55",
        bdis: []
    },
    {
        cpamId: "561",
        dep: "56",
        bdis: []
    },
    {
        cpamId: "571",
        dep: "57",
        bdis: []
    },
    {
        cpamId: "572",
        dep: "57",
        bdis: []
    },
    {
        cpamId: "573",
        dep: "57",
        bdis: []
    },
    {
        cpamId: "581",
        dep: "58",
        bdis: []
    },
    {
        cpamId: "591",
        dep: "59",
        bdis: []
    },
    {
        cpamId: "592",
        dep: "59",
        bdis: []
    },
    {
        cpamId: "593",
        dep: "59",
        bdis: []
    },
    {
        cpamId: "594",
        dep: "59",
        bdis: []
    },
    {
        cpamId: "595",
        dep: "59",
        bdis: []
    },
    {
        cpamId: "596",
        dep: "59",
        bdis: []
    },
    {
        cpamId: "597",
        dep: "59",
        bdis: []
    },
    {
        cpamId: "598",
        dep: "59",
        bdis: []
    },
    {
        cpamId: "599",
        dep: "59",
        bdis: []
    },
    {
        cpamId: "601",
        dep: "60",
        bdis: []
    },
    {
        cpamId: "602",
        dep: "60",
        bdis: []
    },
    {
        cpamId: "611",
        dep: "61",
        bdis: []
    },
    {
        cpamId: "621",
        dep: "62",
        bdis: []
    },
    {
        cpamId: "622",
        dep: "62",
        bdis: []
    },
    {
        cpamId: "623",
        dep: "62",
        bdis: []
    },
    {
        cpamId: "624",
        dep: "62",
        bdis: []
    },
    {
        cpamId: "631",
        dep: "63",
        bdis: []
    },
    {
        cpamId: "641",
        dep: "64",
        bdis: [
            "100",
            "120",
            "122",
            "190",
            "200",
            "210",
            "220",
            "240",
            "250",
            "270",
            "310",
            "340",
            "390",
            "430",
            "480",
            "500",
            "502",
            "520",
            "600",
            "640",
            "700",
            "780",
            "990"
        ]
    },
    {
        cpamId: "642",
        dep: "64",
        bdis: [
            "000",
            "015",
            "110",
            "121",
            "130",
            "140",
            "150",
            "160",
            "170",
            "190",
            "230",
            "234",
            "260",
            "270",
            "290",
            "300",
            "301",
            "320",
            "330",
            "350",
            "360",
            "370",
            "390",
            "400",
            "410",
            "420",
            "440",
            "450",
            "460",
            "470",
            "490",
            "510",
            "530",
            "560",
            "570",
            "660",
            "680",
            "800",
            "850",
            "870"
        ]
    },
    {
        cpamId: "651",
        dep: "65",
        bdis: []
    },
    {
        cpamId: "661",
        dep: "66",
        bdis: []
    },
    {
        cpamId: "671",
        dep: "67",
        bdis: []
    },
    {
        cpamId: "672",
        dep: "67",
        bdis: []
    },
    {
        cpamId: "681",
        dep: "68",
        bdis: []
    },
    {
        cpamId: "682",
        dep: "68",
        bdis: []
    },
    {
        cpamId: "691",
        dep: "69",
        bdis: []
    },
    {
        cpamId: "692",
        dep: "69",
        bdis: []
    },
    {
        cpamId: "701",
        dep: "70",
        bdis: []
    },
    {
        cpamId: "711",
        dep: "71",
        bdis: []
    },
    {
        cpamId: "721",
        dep: "72",
        bdis: []
    },
    {
        cpamId: "731",
        dep: "73",
        bdis: []
    },
    {
        cpamId: "741",
        dep: "74",
        bdis: []
    },
    {
        cpamId: "751",
        dep: "75",
        bdis: []
    },
    {
        cpamId: "759",
        dep: "75",
        bdis: []
    },
    {
        cpamId: "761",
        dep: "76",
        bdis: []
    },
    {
        cpamId: "762",
        dep: "76",
        bdis: []
    },
    {
        cpamId: "763",
        dep: "76",
        bdis: []
    },
    {
        cpamId: "764",
        dep: "76",
        bdis: []
    },
    {
        cpamId: "771",
        dep: "77",
        bdis: []
    },
    {
        cpamId: "781",
        dep: "78",
        bdis: []
    },
    {
        cpamId: "791",
        dep: "79",
        bdis: []
    },
    {
        cpamId: "801",
        dep: "80",
        bdis: []
    },
    {
        cpamId: "811",
        dep: "81",
        bdis: []
    },
    {
        cpamId: "821",
        dep: "82",
        bdis: []
    },
    {
        cpamId: "831",
        dep: "83",
        bdis: []
    },
    {
        cpamId: "841",
        dep: "84",
        bdis: []
    },
    {
        cpamId: "851",
        dep: "85",
        bdis: []
    },
    {
        cpamId: "861",
        dep: "86",
        bdis: []
    },
    {
        cpamId: "871",
        dep: "87",
        bdis: []
    },
    {
        cpamId: "881",
        dep: "88",
        bdis: []
    },
    {
        cpamId: "891",
        dep: "89",
        bdis: []
    },
    {
        cpamId: "901",
        dep: "90",
        bdis: []
    },
    {
        cpamId: "911",
        dep: "91",
        bdis: []
    },
    {
        cpamId: "921",
        dep: "92",
        bdis: []
    },
    {
        cpamId: "931",
        dep: "93",
        bdis: []
    },
    {
        cpamId: "941",
        dep: "94",
        bdis: []
    },
    {
        cpamId: "951",
        dep: "95",
        bdis: []
    },
    {
        cpamId: "971",
        dep: "971",
        bdis: [
            "100",
            "111",
            "112",
            "113",
            "115",
            "116",
            "117",
            "118",
            "119",
            "120",
            "121",
            "122",
            "123",
            "125",
            "126",
            "127",
            "128",
            "129",
            "130",
            "131",
            "133",
            "134",
            "136",
            "137",
            "139",
            "140",
            "141",
            "150",
            "160",
            "171",
            "180"
        ]
    },
    {
        cpamId: "972",
        dep: "972",
        bdis: [
            "128",
            "200",
            "211",
            "213",
            "214",
            "215",
            "216",
            "217",
            "218",
            "220",
            "221",
            "222",
            "223",
            "224",
            "225",
            "227",
            "228",
            "229",
            "230",
            "231",
            "232",
            "234",
            "240",
            "250",
            "260",
            "270",
            "280",
            "290"
        ]
    },
    {
        cpamId: "973",
        dep: "973",
        bdis: [
            "300",
            "310",
            "311",
            "313",
            "314",
            "315",
            "316",
            "317",
            "318",
            "319",
            "320",
            "330",
            "340",
            "350",
            "351",
            "352",
            "353",
            "354",
            "356",
            "360",
            "370",
            "380",
            "390"
        ]
    },
    {
        cpamId: "974",
        dep: "974",
        bdis: [
            "400",
            "410",
            "411",
            "412",
            "413",
            "414",
            "416",
            "417",
            "418",
            "419",
            "420",
            "421",
            "422",
            "423",
            "424",
            "425",
            "426",
            "429",
            "430",
            "431",
            "432",
            "433",
            "434",
            "435",
            "436",
            "437",
            "438",
            "439",
            "440",
            "441",
            "442",
            "450",
            "460",
            "470",
            "480",
            "490"
        ]
    },
    {
        cpamId: "976",
        dep: "976",
        bdis: [
            "600",
            "605",
            "615",
            "620",
            "625",
            "630",
            "640",
            "650",
            "660"
        ]
    },
]
