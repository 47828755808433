export const colors = {
    black: {
        main: '#303030',
        secondary: '#929596'
    },
    grey: {
        main: '#DFE2E1',
        secondary: '#f7f7f7'
    },
    green: {
        main: '#52E09C'
    },
    turquoise: {
        main: '#59CCD3',
        secondary: '#E3FDFF',
        dark: "#55ABB0"
    },
    blue: {
        main: '#7992EA',
        secondary: '#F1F4FF'
    },
    purple: {
        main: '#B880DB'
    },
    pink: {
        main: '#EB6F9B'
    },
    red: {
        main: '#F23A3A',
        secondary: '#f78787'
    },
    orange: {
        main: '#F89136',
        secondary: '#FFF0CA'
    }
}
