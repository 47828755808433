
import { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';

import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { MainHeader } from '../../components/Header/MainHeader';

import { FormInitializing } from '../form/FormInitializing';
import { FormOfferSelect } from '../form/FormOfferSelect';
import { FormBeneficiaries } from '../form/FormBeneficiaries';
import { FormContextError } from '../form/FormContextError';
import { FormContractBilling } from '../form/FormContractBilling';
import { FormContractDisplay } from '../form/FormContractDisplay';
import { FormContractSignValidate } from '../form/FormContractSignValidate';
import { FormContractSignSend } from '../form/FormContractSignSend';
import { FormDocumentsDisplay } from '../form/FormDocumentsDisplay';
import { FormDocumentsGenerate } from '../form/FormDocumentsGenerate';
import { FormSubscriberAdress } from '../form/FormSubscriberAdress';

import { getFormContext, modifyForm } from '../../api/Form.api';
import { getFormDocuments } from '../../api/FormDocument.api';
import { createFormHistory } from '../../api/FormHistory.api';
import { getPlateformeSettings } from '../../api/PlateformeSettings.api';

import { IContract } from '../../model/Contract.model';
import { IContractBilling } from '../../model/ContractBilling.model';
import { IContractDebiting } from '../../model/ContractDebiting.model';
import { getFormuleRecommendedId, IContractNeeds } from '../../model/ContractNeeds.model';
import { IContractSignature } from '../../model/ContractSignature.model';
import { IForm } from '../../model/Form.model';
import { IFormContext } from '../../model/FormContext.model'
import { IFormDocument } from '../../model/FormDocument.model';
import { formulesStyles, IFormule, IFormuleDetails } from '../../model/Formule.model';
import { IPerson } from '../../model/Person.model';
import { IPlateforme } from '../../model/Plateforme.model';
import { IPlateformeSettings } from '../../model/PlateformeSettings.model';

import { theme as themeGSMC } from '../../static/themes/gsmc/theme'
import { style as styleGSMC } from '../../static/themes/gsmc/style'
import { getPlateformStyle, getPlateformTheme } from '../../static/themes/theme';

import {
    enumContractBillingTransactionTypeAll,
    enumContractBillingTransactionTypeIncome,
    enumContractBillingTransactionTypePayment,
    enumFormDocumentTypeAttestationCPAM,
    enumFormDocumentTypeCniRecto,
    enumFormDocumentTypeCniVerso,
    enumFormDocumentTypeKbis,
    enumFormDocumentTypeRIB,
    enumHrRoleChild,
    enumHrRoleConjoint,
    enumHrRoleSubscriber, formSignatureOnlySteps, FormStep,
    formSteps, formStepsCourtier
} from '../../utils';

import { sendStepGTM } from '../../services/gtm'
import { getTarificationFromContext } from '../../services/Tarification';
import { PageLayout } from '../../components/Layout/PageLayout';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { ErrorContent } from '../error/ErrorContent';
import { CardInfo } from '../../components/Card/CardInfo';
import { IPromotion } from '../../model/Promotion.model';
import { FormContractBrokerSignEmail } from "../form/FormContractBrokerSignEmail";
import { getWarrantiesAcronyms, getWarrantiesForFormules } from "../../api/Warranties.api";
import { IWarrantiesAcronyms, IWarrantiesSection } from "../../model/Waranties.model";
import WarrantiesProvider from "../../components/Offer/WarrantiesContext";
import * as Sentry from "@sentry/react";

interface Props {
    steps?: FormStep[],
}

export const Lead = (props: Props) => {

    document.title = 'Mutuelle GSMC';

    // Theming.
    const [theme, setTheme] = useState<any>(themeGSMC);
    const [plateformStyle, setPlateformStyle] = useState<any>(styleGSMC);

    // URL parameters.
    const urlFormUuid = useParams<{ uuid: string }>().uuid

    // Steps management.
    const [activeStep, setActiveStep] = useState<number>(0);
    const [activeInnerStep, setActiveInnerStep] = useState<number>(0);
    const [isContextInitialized, setIsContextInitialized] = useState<boolean | null>(false);
    const [goToPreviousFlag, setGoToPreviousFlag] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<number>();

    // Form.
    const [form, setForm] = useState<IForm>({ lastStepCompleted: undefined, lastInnerStepCompleted: undefined });
    const [plateform, setPlateform] = useState<IPlateforme>({ name: "", code: "", idHenner: 0, codeHenner: "", codeCrm: "", isActive: true, isGSMC: false, isBroker: false });
    const [plateformSettings, setPlateformSettings] = useState<IPlateformeSettings>();
    const [snackbarEmailDisplayed, setSnackbarEmailDisplayed] = useState<boolean>(true);


    const handleGTM = () => {

        // Only in production.
        if (process.env.REACT_APP_PRODUCTION !== '1')
            return

        if (form.uuid === undefined || form.uuid === null)
            return;

        let currentStep = (form.lastStepCompleted === null || form.lastStepCompleted === undefined) ? 0 : form.lastStepCompleted + 1;
        let currentInnerStep = (form.lastInnerStepCompleted === null || form.lastInnerStepCompleted === undefined) ? 0 : form.lastInnerStepCompleted + 1;

        // First time on the tunnel.
        let isFormStart: boolean = form.techDateModification === undefined || form.techDateModification === null;

        // Check if it's not a return to a previous step.
        if (!isFormStart &&
            (currentStep > activeStep ||
                (currentStep === activeStep && currentInnerStep >= activeInnerStep)))
            return;

        sendStepGTM(
            isFormStart,
            activeStep,
            activeInnerStep,
            form.uuid,
            contractNeeds.codeOffre,
            plateform.name,
            plateform.isGSMC,
            formule?.formuleLibelle,
            formule?.tarif || 0);
    }

    useEffect(() => {
        if (!isContextInitialized)
            return;

        // Manage Form history.
        if (form.id !== undefined && form.id !== null) {
            createFormHistory({
                form: form,
                step: activeStep.toString(),
                innerStep: activeInnerStep.toString()
            });
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }

        // Manage GTM.
        handleGTM();

    }, [isContextInitialized, activeStep, activeInnerStep])

    useEffect(() => {
        if (!plateform?.code) return

        setTheme(getPlateformTheme(plateform.code));
        setPlateformStyle(getPlateformStyle(plateform.code));
    }, [plateform?.code])

    const getFormPage = () => {
        if (!isContextInitialized)
            return;

        if (!plateformSettings)
            return <FormContextError message="Une erreur est survenue lors de la récupération de vos informations de votre demande d’adhésion." />;

        switch (activeStep) {
            case (0):
                switch (activeInnerStep) {
                    default:
                    case (0):
                        return <FormOfferSelect
                            plateformStyle={plateformStyle}
                            formulesDetails={formulesDetails}
                            form={form}
                            contractNeeds={contractNeeds}
                            subscriber={subscriber}
                            conjoint={conjoint}
                            children={children}
                            promotions={promotions}
                            enableEstimation={plateformSettings.settingsEstimation.enableEstimation}
                            sendSubscriber={updateSubscriber}
                            sendConjoint={updateConjoint}
                            sendChildren={updateChildren}
                            sendFormule={updateFormule}
                            sendContractNeeds={updateNeeds}
                            goToPreviousFlag={goToPreviousFlag}
                            goToStepBenef={
                                form.lastStepCompleted !== undefined && form.lastStepCompleted !== null && form.lastStepCompleted >= 0 &&
                                    form.lastInnerStepCompleted !== undefined && form.lastInnerStepCompleted !== null && form.lastInnerStepCompleted >= 0 ?
                                    () => {
                                        setActiveStep(1)
                                        setActiveInnerStep(0)
                                    }
                                    :
                                    undefined
                            }
                            goToNext={goToNextInnerStep}
                            goToPrevious={goToPreviousInnerStep} />;
                }

            case (1):
                switch (activeInnerStep) {
                    default:
                    case (0):
                        return <FormBeneficiaries
                            plateformStyle={plateformStyle}
                            sidebar={sidebar}
                            isUpdated={
                                form.lastStepCompleted === undefined || form.lastStepCompleted === null ? false :
                                    form.lastStepCompleted > 0 ? true :
                                        form.lastInnerStepCompleted === undefined || form.lastInnerStepCompleted === null ? false :
                                            form.lastInnerStepCompleted >= 0 ? true : false
                            }
                            form={form}
                            subscriber={subscriber}
                            conjoint={conjoint}
                            children={children}
                            sendSubscriber={(_) => updateSubscriber({ ..._, adress: subscriber.adress })}
                            sendConjoint={updateConjoint}
                            deleteConjoint={deleteConjoint}
                            sendChild={updateChild}
                            deleteChild={deleteChild}
                            isControlFraud={plateformSettings.settingsFraud.enableControlPhone}
                            goToPreviousFlag={goToPreviousFlag}
                            goToNext={goToNextInnerStep}
                            goToPrevious={goToPreviousInnerStep} />

                    case (1):
                        return <FormSubscriberAdress
                            plateformStyle={plateformStyle}
                            sidebar={sidebar}
                            subscriber={subscriber}
                            sendSubscriber={(_) => updateSubscriber({ ...subscriber, regime: _.regime, adress: _.adress })}
                            goToPreviousFlag={goToPreviousFlag}
                            goToNext={goToNextInnerStep}
                            goToPrevious={goToPreviousInnerStep} />;

                    case (2):
                        return <FormContractBilling
                            plateformStyle={plateformStyle}
                            plateformSettings={plateformSettings}
                            sidebar={sidebar}
                            form={form}
                            subscriber={subscriber}
                            contract={contract}
                            billingMain={contractBillingMain}
                            billingSecondary={contractBillingSecondary}
                            debiting={contractDebiting}
                            isControlFraud={plateformSettings.settingsFraud.enableControlIban}
                            sendContract={updateContract}
                            sendBilling={updateContractBilling}
                            sendDebiting={updateContractDebiting}
                            goToPreviousFlag={goToPreviousFlag}
                            goToNext={goToNextInnerStep}
                            goToPrevious={goToPreviousInnerStep} />;
                }

            case (2):
                switch (steps[2].innerStepsNumber) {
                    default:
                    case 5:
                        switch (activeInnerStep) {
                            default:
                            case (0):
                                return <FormDocumentsGenerate
                                    plateformStyle={plateformStyle}
                                    sidebar={sidebar}
                                    form={form}
                                    plateforme={plateform}
                                    subscriber={subscriber}
                                    conjoint={conjoint}
                                    childrens={children}
                                    contract={contract}
                                    contractSignature={contractSignature}
                                    contractNeeds={contractNeeds}
                                    billingMain={contractBillingMain}
                                    promotions={promotions}
                                    goToNext={goToNextInnerStep}
                                    goToPrevious={goToPreviousInnerStep} />;

                            case (1):
                                return <FormContractDisplay
                                    plateformStyle={plateformStyle}
                                    isUpdated={
                                        form.lastStepCompleted === undefined || form.lastStepCompleted === null ? false :
                                            form.lastStepCompleted > 1 ? true :
                                                form.lastInnerStepCompleted === undefined || form.lastInnerStepCompleted === null ? false :
                                                    form.lastStepCompleted === 1 && form.lastInnerStepCompleted >= 1 ? true : false
                                    }
                                    sidebar={sidebar}
                                    formUuid={form.uuid ? form.uuid : ""}
                                    goToPreviousFlag={goToPreviousFlag}
                                    goToNext={goToNextInnerStep}
                                    goToPrevious={goToPreviousInnerStep} />

                            case (2):
                                return <FormContractSignSend
                                    plateformStyle={plateformStyle}
                                    sidebar={sidebar}
                                    form={form}
                                    subscriber={subscriber}
                                    sendSubscriber={(_) => updateSubscriber({
                                        ...subscriber,
                                        phoneMobile: _.phoneMobile
                                    })}
                                    isControlFraud={plateformSettings.settingsFraud.enableControlPhone}
                                    goToPreviousFlag={goToPreviousFlag}
                                    goToNext={goToNextInnerStep}
                                    goToPrevious={goToPreviousInnerStep} />;

                            case (3):
                                return <FormContractSignValidate
                                    plateformStyle={plateformStyle}
                                    sidebar={sidebar}
                                    codeOffer={contractNeeds.codeOffre}
                                    contractSignature={contractSignature}
                                    contract={contract}
                                    plateform={plateform}
                                    subscriber={subscriber}
                                    sendContractSignature={updateContractSignature}
                                    goToPreviousFlag={goToPreviousFlag}
                                    goToNext={goToNextInnerStep}
                                    goToPrevious={goToPreviousInnerStep} />;

                            case (4):
                                return <FormDocumentsGenerate
                                    plateformStyle={plateformStyle}
                                    signature
                                    sidebar={sidebar}
                                    form={form}
                                    plateforme={plateform}
                                    subscriber={subscriber}
                                    conjoint={conjoint}
                                    contractNeeds={contractNeeds}
                                    childrens={children}
                                    contract={contract}
                                    contractSignature={contractSignature}
                                    billingMain={contractBillingMain}
                                    promotions={promotions}
                                    goToNext={goToNextInnerStep}
                                    goToPrevious={goToPreviousInnerStep} />;
                        }
                    case 1:
                        return <FormContractBrokerSignEmail
                            plateformStyle={plateformStyle}
                            sidebar={sidebar}
                            formUuid={form.uuid}
                            subscriber={subscriber}
                            goToPreviousFlag={goToPreviousFlag}
                            goToNext={goToNextInnerStep}
                            goToPrevious={goToPreviousInnerStep} />;
                }

            case (3):
                switch (activeInnerStep) {
                    default:
                    case (0):
                        return <FormDocumentsDisplay
                            plateformStyle={plateformStyle}
                            plateformSettings={plateformSettings}
                            sidebar={sidebar}
                            form={form}
                            codeOffer={contractNeeds.codeOffre}
                            subscriber={subscriber}
                            snackbarDisplayed={snackbarEmailDisplayed}
                            sendSnackbarDisplayed={setSnackbarEmailDisplayed}
                            goToNext={goToNextInnerStep}
                            goToPrevious={goToPreviousInnerStep} />;
                }
            default:
                return <FormInitializing />;
        }
    }

    const goToStep = (index: number) => {

        if ((steps[2]?.innerStepsNumber > 1 && ((activeStep === 2 && activeInnerStep === 0) ||
            (activeStep === 2 && activeInnerStep === 5))) ||
            (activeStep === 3 && activeInnerStep === 0))
            return;

        setActiveStep(index);
        setActiveInnerStep(0);
    }

    const goToNextInnerStep = () => {
        // End of the form.
        if (activeStep === steps.length - 1)
            return;

        // Check contractDate from sidebar.
        if (refSidebar.current && activeStep < 2) {
            let isFormValid = refSidebar.current.handleSubmit();
            if (!isFormValid) {
                refSidebar.current.focusForm()
                return;
            }
        }

        // Next Inner Step.
        if (activeInnerStep + 1 <= steps[activeStep].innerStepsNumber - 1)
            setActiveInnerStep((prevActiveInnerStep) => prevActiveInnerStep + 1);

        // Next Step.
        else {
            setActiveInnerStep(0);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const goToPreviousInnerStep = () => {
        setGoToPreviousFlag(false);

        // Check contractDate from sidebar.
        if (refSidebar.current && activeStep < 2) {
            let isFormValid = refSidebar.current.handleSubmit();
            if (!isFormValid) {
                refSidebar.current.focusForm()
                return;
            }
        }

        if (activeInnerStep === 0) {
            if (activeStep === 0)
                return;
            else {
                setActiveInnerStep(steps[activeStep - 1].innerStepsNumber - 1);
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }
        }
        else {
            if ((activeStep === 2 && activeInnerStep === 1) ||
                (activeStep === 3 && activeInnerStep === 0)) { // CAS Spécial pour éviter les concurrences de génération de fichiers.
                setActiveInnerStep(steps[activeStep - 1].innerStepsNumber - 1);
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }
            else {
                setActiveInnerStep((prevActiveInnerStep) => prevActiveInnerStep - 1);
            }
        }
    };

    // Update form status.
    useEffect(() => {
        if (!isContextInitialized ||
            form.isCompleted)
            return;

        let currentStep = (form.lastStepCompleted === null || form.lastStepCompleted === undefined) ? 0 : form.lastStepCompleted + 1;
        let currentInnerStep = (form.lastInnerStepCompleted === null || form.lastInnerStepCompleted === undefined) ? 0 : form.lastInnerStepCompleted + 1;

        // Previous Step.
        if (currentStep > activeStep ||
            (currentStep === activeStep && currentInnerStep >= activeInnerStep))
            return;

        let f: IForm = form;

        // Form completed.
        if (activeStep === steps.length - 1 && activeInnerStep === steps[steps.length - 1].innerStepsNumber - 1) {
            f.isCompleted = true;
            f.lastStepCompleted = steps.length - 1;
            f.lastInnerStepCompleted = steps[steps.length - 1].innerStepsNumber - 1;
        }

        // Next Step.
        else {
            f.lastInnerStepCompleted = activeInnerStep === 0 ? undefined : activeInnerStep - 1;
            f.lastStepCompleted = activeStep === 0 ? undefined : activeStep - 1;
        }

        modifyForm(f).then((response: IForm) => {
            if (!response.id) {
                // TODO : Gestion erreur.
                Sentry.captureMessage("modifyForm - Exception",
                    {
                        level: 'error',
                        extra: {
                            response: response,
                            request: f,
                        }
                    }
                );
            }
            setForm(response);  // Update State.
        });

    }, [activeStep,
        activeInnerStep])

    // Document management.
    const [documentCniRecto, setDocumentCniRecto] = useState<IFormDocument>({ form: form, type: enumFormDocumentTypeCniRecto });
    const [documentCniVerso, setDocumentCniVerso] = useState<IFormDocument>({ form: form, type: enumFormDocumentTypeCniVerso });
    const [documentKbis, setDocumentKbis] = useState<IFormDocument>({ form: form, type: enumFormDocumentTypeKbis });
    const [documentRib, setDocumentRib] = useState<IFormDocument>({ form: form, type: enumFormDocumentTypeRIB });
    const [documentsAttestationsCPAM, setDocumentsAttestationsCPAM] = useState<IFormDocument[]>([]);

    const updateDocumentCniRecto = (doc: IFormDocument) => {
        setDocumentCniRecto(doc);
    }

    const updateDocumentCniVerso = (doc: IFormDocument) => {
        setDocumentCniVerso(doc);
    }

    const updateDocumentKbis = (doc: IFormDocument) => {
        setDocumentKbis(doc);
    }

    const updateDocumentRib = (doc: IFormDocument) => {
        setDocumentRib(doc);
    }

    const updateDocumentsAttestationsCPAM = (docs: IFormDocument[]) => {
        setDocumentsAttestationsCPAM(docs);
    }


    // HR management - Person.
    const [subscriber, setSubscriber] = useState<IPerson>({ role: enumHrRoleSubscriber, dateBirth: new Date() });
    const [conjoint, setConjoint] = useState<IPerson>();
    const [children, setChildren] = useState<IPerson[]>([]);
    const updateSubscriber = (person: IPerson) => {
        setSubscriber(_ => {
            let dateBirth: any = !_?.dateBirth ? null : typeof _.dateBirth === "string" ? new Date(_.dateBirth as string) : _.dateBirth;
            let newDateBirth: any = !person?.dateBirth ? null : typeof person.dateBirth === "string" ? new Date(person.dateBirth as string) : person.dateBirth;

            if (dateBirth === null || newDateBirth === null || dateBirth.getTime() !== newDateBirth.getTime())
                dateBirth = newDateBirth;

            return { ...person, dateBirth: dateBirth }
        })
    }

    const updateNeeds = (needs: IContractNeeds) => {
        setContractNeeds(needs);
    }

    const updateConjoint = (person?: IPerson) => {

        if (person === undefined) {
            setConjoint(undefined)
            return
        }

        person.role = enumHrRoleConjoint;

        setConjoint(_ => {

            let dateBirth: any = !_?.dateBirth ? null : typeof _.dateBirth === "string" ? new Date(_.dateBirth as string) : _.dateBirth;
            let newDateBirth: any = !person?.dateBirth ? null : typeof person.dateBirth === "string" ? new Date(person.dateBirth as string) : person.dateBirth;

            if (dateBirth === null || dateBirth.getTime() !== newDateBirth.getTime())
                dateBirth = newDateBirth;

            return { ...person, dateBirth: dateBirth, regime: _?.regime || person.regime || subscriber.regime }
        })
    }

    const deleteConjoint = (person: IPerson) => {
        setConjoint(undefined);
    }

    const updateChildren = (persons?: IPerson[]) => {
        let newChildren: IPerson[] = []

        persons && persons.forEach((_, id) => {
            newChildren.push(_)
        })

        setChildren(newChildren)
    }

    const updateChild = (person: IPerson, index?: number) => {
        person.role = enumHrRoleChild;

        if (index === undefined) {
            setChildren([...children, person]);
        }
        else {
            setChildren((prevChildren) => {
                let newChilds = [...prevChildren]
                newChilds[index] = person;
                return newChilds;
            });
        }
    }

    const deleteChild = (person: IPerson, index: number) => {
        setChildren((prevChildren) => {
            let newChilds = [...prevChildren]
            newChilds.splice(index, 1);
            return newChilds;
        });
    }


    // Formule management.
    const [formule, setFormule] = useState<IFormule>();
    const [formulesDetails, setFormulesDetails] = useState<IFormuleDetails[]>();
    const [formuleLoading, setFormuleLoading] = useState<Boolean>(false);
    const [formuleRecommendedId, setFormuleRecommendedId] = useState<number>();
    const [promotions, setPromotions] = useState<IPromotion[]>([]);
    const [steps, setSteps] = useState<FormStep[]>(props.steps || formSteps);

    const [warranties, setWarranties] = useState<IWarrantiesSection[]>([]);
    const [warrantiesAcronyms, setWarrantiesAcronyms] = useState<IWarrantiesAcronyms[]>([]);

    const getFormule = () => {
        if (form.uuid) {
            if (contract?.priceContract && form.isCompleted && contractNeeds?.formule?.idHenner) {
                const formule: IFormule = {
                    identifiant: parseInt(contractNeeds?.formule?.idHenner) || 0,
                    formuleLibelle: contractNeeds?.formule?.formuleHenner,
                    beneficiaires: [{
                        acceptationEdi: false,
                        dateDeNaissance: new Date(),
                        lienDeParente: "AF",
                        tarif: contract.priceContract
                    }]
                };

                setFormule(formule);
                return;
            }

            setFormuleLoading(true);

            if (contract.dateStart === undefined || subscriber?.adress?.postalCode === undefined) return

            let benef: IPerson[] = [subscriber];

            if (conjoint && conjoint.dateBirth !== null && conjoint.dateBirth !== undefined)
                benef.push(conjoint)

            benef = benef.concat(children.filter(_ => _.dateBirth !== undefined))

            getTarificationFromContext(
                form.uuid,
                contractNeeds.codeOffre,
                contract.dateStart,
                subscriber?.adress?.postalCode,
                benef
            ).then(response => {

                let formules: IFormuleDetails[] = [];

                const recommendedId: number | undefined = getFormuleRecommendedId(contractNeeds);

                if (recommendedId) {
                    setFormuleRecommendedId(undefined);
                    setFormuleRecommendedId(recommendedId);
                }

                response.forEach((_) => {
                    let formuleStyle;
                    let formuleStylePlateform = plateformStyle.components.Formules.colors.find((e: any) => e.id === _.identifiant);
                    let formuleDetails: IFormuleDetails;

                    // Get formule style.
                    formuleStyle = formulesStyles.find((e) => e.id === _.identifiant);  // TODO : Faire en fonction de l'ordre selon henner ID ?

                    formuleDetails = {
                        formule: _,
                        description: formuleStyle?.description || "",
                        coverPercentage: formuleStyle?.coverPercentage || 50,
                        color: formuleStylePlateform?.color || plateformStyle.components.Formules.defaultColor || formuleStyle?.color,
                        isRecommended: _.identifiant === recommendedId
                    };
                    formules.push(formuleDetails);
                })

                // REPLACEME : The formulas are ordered by coverage percentage. In the futur the order will be done with a rank saved in DB (MS : referentiels).
                setFormulesDetails(formules.sort((a, b) => a.coverPercentage - b.coverPercentage));

                let idHenner: number | undefined = contractNeeds?.formule?.idHenner ? parseInt(contractNeeds.formule.idHenner) : undefined;

                setFormule(response.find(_ => _.identifiant === idHenner));
                setFormuleLoading(false);

                let formuleIds = formules?.map(formuleDetails => formuleDetails.formule.identifiant.toString());
                getWarrantiesForFormules(formuleIds ? formuleIds : [])
                    .then(response => {
                        setWarranties(response);
                    });
            }).catch((exception) => {
                Sentry.captureException(exception);
                setFormuleLoading(false);
            });

            // COMMENT : Old API route.
            // getTarifFormule(form.uuid)
            // .then(response => {
            //     let idHenner: number|undefined = contractNeeds?.formule?.idHenner ? parseInt(contractNeeds.formule.idHenner) : undefined;

            //     setFormule(response.find(_ => _.identifiant === idHenner));
            //     setFormuleLoading(false);
            // })
            // .catch((exception) => {
            //     // TODO : sentry.
            //     setFormuleLoading(false);
            // });
        }
    }

    const updateFormule = (f: IFormule) => {
        setFormule(f);
    }


    // Contract management.
    const [contractDebiting, setContractDebiting] = useState<IContractDebiting>({ dateDebiting: null });
    const [contract, setContract] = useState<IContract>({ person: subscriber, debiting: contractDebiting });
    const [contractNeeds, setContractNeeds] = useState<IContractNeeds>({ contract: contract, codeOffre: "" });
    const [contractSignature, setContractSignature] = useState<IContractSignature>({ contract: contract });
    const [contractBillingMain, setContractBillingMain] = useState<IContractBilling>({
        contract: contract,
        typeTransaction: enumContractBillingTransactionTypeAll,
        isActive: true
    });
    const [contractBillingSecondary, setContractBillingSecondary] = useState<IContractBilling>({
        contract: contract,
        typeTransaction: enumContractBillingTransactionTypeIncome,
        isActive: false
    });

    const updateContract = (contract: IContract) => {
        setContract(contract);
    }

    const updateContractSignature = (contractSignature: IContractSignature) => {
        setContractSignature(contractSignature);
    }

    const updateContractBilling = (billing: IContractBilling) => {
        if (billing.typeTransaction === enumContractBillingTransactionTypeAll ||
            billing.typeTransaction === enumContractBillingTransactionTypePayment)
            setContractBillingMain(billing);
        else
            setContractBillingSecondary(billing);
    }

    const updateContractDebiting = (debiting: IContractDebiting) => {
        setContractDebiting(debiting);
    }

    // Update the form if its props changes.
    useEffect(() => {
        if (isContextInitialized)
            setFormPage(getFormPage());
    }, [activeStep,
        activeInnerStep,
        children,
        conjoint,
        contractBillingMain,
        contractBillingSecondary,
        contractDebiting,
        contractNeeds,
        contractSignature,
        documentCniRecto,
        documentCniVerso,
        documentKbis,
        documentRib,
        documentsAttestationsCPAM,
        isContextInitialized,
        form,
        goToPreviousFlag,
        plateformSettings,
        subscriber,
        formule,
        formulesDetails,
        formuleLoading,
        warranties])

    // Initialize States after the first render() lifecycle.
    useEffect(() => {
        let uuid: string | undefined = urlFormUuid;

        // Get the UUID from the URL.
        if (uuid)
            localStorage.setItem('form_uuid', uuid.toString());

        // Get the UUID from the local storage.
        else
            uuid = localStorage.getItem('form_uuid') || undefined;

        // No UUID in the URL or in the localstorage.
        if (!uuid)
            return;

        getFormContext(uuid)
            .then((response: IFormContext) => {
                if ((response as any).status)
                    throw new Error((response as any).status)

                // Mandatory objects.
                setForm(response.form);
                setPlateform(response.plateform);
                setContract(response.contract);
                setContractNeeds(response.contractNeeds);
                setSubscriber(response.subscriber);


                if (!props.steps && response.plateform.isBroker) {
                    if (response.plateform.isBroker) {
                        setSteps(formStepsCourtier);
                    }
                }

                // Optional objects.
                if (response.contractNeeds.formule) setFormule({ identifiant: parseInt(response.contractNeeds.formule.idHenner) });
                if (response.contractSignature) setContractSignature(response.contractSignature);
                if (response.contractBillingMain) setContractBillingMain(response.contractBillingMain);
                if (response.contractBillingSecondary) setContractBillingSecondary(response.contractBillingSecondary);
                if (response.contractDebiting) setContractDebiting(response.contractDebiting);
                if (response.conjoint)
                    setConjoint({ ...response.conjoint, regime: response.conjoint.regime !== undefined && response.conjoint.regime !== null ? response.conjoint.regime : response.subscriber.regime })
                if (response.children) setChildren(response.children);
                if (response.promotions) setPromotions(response.promotions);

                setSnackbarEmailDisplayed(response.form.isCompleted || false);

                // Set the active Steps.
                let step: number;
                let innerStep: number;

                if (response.form.lastStepCompleted === undefined || response.form.lastStepCompleted === null)
                    step = 0;
                else if (response.form.lastStepCompleted === steps.length - 1)
                    step = response.form.lastStepCompleted;  // KO
                else
                    step = response.form.lastStepCompleted + 1;

                if (response.form.lastInnerStepCompleted === undefined || response.form.lastInnerStepCompleted === null || response.form.lastInnerStepCompleted === 0)
                    innerStep = 0;
                else if (response.form.lastInnerStepCompleted === formSteps[step].innerStepsNumber - 1)
                    innerStep = (response.form.lastInnerStepCompleted);
                else
                    innerStep = response.form.lastInnerStepCompleted + 1;

                // loop to be sure to be back at the first non empty step
                while (steps[step] && steps[step].innerStepsNumber === 0) {
                    step += 1;
                    innerStep = 0;
                }

                setActiveStep(step);
                setActiveInnerStep(innerStep);

                if (!response.form.uuid)
                    return;

                return getPlateformeSettings(response.plateform.code);
            })
            .then((response?: IPlateformeSettings) => {
                if ((response as any).status)
                    throw new Error((response as any).status)

                if (!uuid)  // Should never append.
                    return;

                // Get plateforme settings.
                setPlateformSettings(response);

                // Context initialized.
                setIsContextInitialized(true);

                return getFormDocuments(uuid);
            })
            .then((response?: IFormDocument[]) => {
                if ((response as any).status)
                    throw new Error((response as any).status)

                if (response && response.length > 0) {

                    let doc: IFormDocument | undefined;

                    doc = response.find(e => e.type === enumFormDocumentTypeCniRecto);
                    if (doc) setDocumentCniRecto(doc);

                    doc = response.find(e => e.type === enumFormDocumentTypeCniVerso);
                    if (doc) setDocumentCniVerso(doc);

                    doc = response.find(e => e.type === enumFormDocumentTypeKbis);
                    if (doc) setDocumentKbis(doc);

                    doc = response.find(e => e.type === enumFormDocumentTypeRIB);
                    if (doc) setDocumentRib(doc);

                    setDocumentsAttestationsCPAM(response.filter(e => e.type === enumFormDocumentTypeAttestationCPAM));
                }

                return getWarrantiesAcronyms();
            })
            .then((response?: IWarrantiesAcronyms[]) => {
                if ((response as any).status)
                    throw new Error((response as any).status);

                if (!response) {
                    return;
                }

                setWarrantiesAcronyms(response);
            })
            .catch((exception) => {
                // navigate(`/error/404?lead=${uuid}`)
                setIsContextInitialized(null)
                setErrorCode(parseInt(exception.message))

                getPlateformeSettings("GSMC")
                    .then((response?: IPlateformeSettings) => {
                        if ((response as any).status)
                            throw new Error((response as any).status)

                        setPlateformSettings(response);
                    })
                    .catch((exception) => { });
            });
    }, []);

    useEffect(() => {
        if (isContextInitialized)
            getFormule();
    }, [isContextInitialized,
        contractNeeds?.codeOffre,
        contractNeeds?.formule,
        contract?.dateStart,
        conjoint?.dateBirth,
        subscriber?.adress?.postalCode,
        subscriber?.dateBirth,
        subscriber?.regime,
        children])

    useEffect(() => {
        if (isContextInitialized && contractNeeds?.codeOffre) {
            const recommendedId: number | undefined = getFormuleRecommendedId(contractNeeds);

            if (recommendedId)
                setFormuleRecommendedId(recommendedId);
        }
    }, [isContextInitialized,
        contractNeeds])


    useEffect(() => {
        if (isContextInitialized && formulesDetails && formulesDetails.length !== 0) {
            let formules: IFormuleDetails[] = [];
            formulesDetails.forEach(_ => {
                _.isRecommended = _.formule.identifiant === formuleRecommendedId;
                formules.push(_);
            });

            setFormulesDetails(formules);
        }
    }, [formuleRecommendedId])


    const [formPage, setFormPage] = useState<JSX.Element | undefined>(<FormInitializing />);

    const refSidebar = useRef<any>(null)
    const sidebar: JSX.Element = <Sidebar
        ref={refSidebar}
        plateformStyle={plateformStyle}
        plateform={plateform}
        codeOffer={contractNeeds.codeOffre}
        formule={formule}
        formuleLoading={formuleLoading}
        contract={contract}
        showContractForm={!(activeStep >= 2 || form.isCompleted)}
        promotions={promotions}
        sendContract={setContract}
        updatePlateform={() => {
            if (!urlFormUuid) return;
            getFormContext(urlFormUuid)
                .then((response: IFormContext) => {
                    if ((response as any).status)
                        throw new Error((response as any).status)

                    setPlateform(response.plateform);
                    return getPlateformeSettings(response.plateform.code);
                })
                .then((response?: IPlateformeSettings) => {
                    if ((response as any).status)
                        throw new Error((response as any).status)
                    setPlateformSettings(response);
                })
        }}
    />

    return (
        <WarrantiesProvider value={{ warranties: warranties, setWarranties: setWarranties, warrantiesAcronyms: warrantiesAcronyms, setWarrantiesAcronyms: setWarrantiesAcronyms }}>
            <PageLayout
                theme={theme}
                header={
                    <MainHeader
                        plateformStyle={plateformStyle}
                        plateformSettings={plateformSettings}
                        form={form}
                        subscriber={subscriber}
                        isReturnEnabled={!(activeStep === 0 && activeInnerStep === 0) &&  // Start
                            !(activeStep === 2 && activeInnerStep === 0) &&   // Contract Generation
                            !(activeStep === 2 && activeInnerStep === 5) &&   // Contract Generation Signed
                            !(activeStep === 3 && activeInnerStep === 0) &&     // Final
                            !(steps === formSignatureOnlySteps && activeStep === 2 && [0, 1].includes(activeInnerStep))} // Contract generation + contract display for broker client
                        goToPrevious={() => setGoToPreviousFlag(true)} />}
                content={
                    isContextInitialized === null && errorCode ?
                        <Box
                            sx={{
                                maxWidth: '1200px',
                                mx: 'auto',
                                px: 2
                            }}>
                            {errorCode === 404 ?
                                <ErrorContent
                                    code="404"
                                    color="warning"
                                    title="La demande d’adhésion que vous cherchez est introuvable."
                                    content={
                                        <CardInfo plateformStyle={plateformStyle}>
                                            <Typography fontWeight={500}>Votre demande d’adhésion a dû expirer.<br />Vous disposiez de <b>30 jours</b> pour finaliser votre demande d’adhésion.</Typography>
                                        </CardInfo>} />
                                :
                                <ErrorContent
                                    code={errorCode?.toString()}
                                    color={errorCode / 100 === 4 ? "warning" : "error"} />
                            }
                        </Box>

                        :
                        <>
                            <Box
                                sx={{
                                    maxWidth: '800px',
                                    mx: 'auto',
                                    px: 2
                                }}>
                                <Header
                                    plateformStyle={plateformStyle}
                                    steps={steps}
                                    activeStep={activeStep}
                                    activeInnerStep={activeInnerStep}
                                    form={form}
                                    goToStep={goToStep} />
                            </Box>
                            <Box
                                sx={{
                                    maxWidth: '1200px',
                                    mx: 'auto'
                                }}>
                                {formPage}
                            </Box>
                        </>
                }
                footer={
                    <Box
                        sx={{
                            maxWidth: '1200px',
                            mx: 'auto'
                        }}>
                        {plateformSettings &&
                            <Footer
                                plateformStyle={plateformStyle}
                                plateformSettings={plateformSettings}
                                form={form.id ? form : undefined}
                                subscriber={subscriber} />}
                    </Box>
                } />
        </ WarrantiesProvider>
    )
}
