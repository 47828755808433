import Grid from '@mui/material/Grid';

import { OfferDetailsBaseWarranties } from "./OfferDetailsBaseWarranties";
import { useWarantiesContext } from "./WarrantiesContext";
import { sortByOrder } from "../../utils/sortUtils";


interface Props {
    plateformStyle: any;
    codeOffer: string;
    formulesDetailsLength: number;
    formuleCenterId: number;
    formuleLeftId?: number;
    formuleRightId?: number;
    formuleSelectedId?: number;
    formuleRecommendedId?: number;
}


export const OfferDetails = (props: Props) => {
    const [warranties] = useWarantiesContext();

    const plateformStyle: any = props.plateformStyle;

    return (
        <Grid
            xs={12}
            item
            sx={{
                mt: 2
            }} >
            <Grid
                container
                spacing={2}>

                {warranties.sort((a, b) => {
                    return sortByOrder(a.order, b.order);
                }).map((section, index) => {
                    return (<Grid
                        item
                        xs={12}>
                        <OfferDetailsBaseWarranties
                            plateformStyle={plateformStyle}
                            codeOffer={props.codeOffer}
                            formulesDetailsLength={props.formulesDetailsLength}
                            formuleCenterId={props.formuleCenterId}
                            formuleLeftId={props.formuleLeftId}
                            formuleRightId={props.formuleRightId}
                            formuleSelectedId={props.formuleSelectedId}
                            formuleRecommendedId={props.formuleRecommendedId}
                            isOpen={index === 0}
                            warrantiesSection={section} />
                    </Grid>);
                })}
            </Grid>
        </Grid>
    )
}
