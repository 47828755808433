export const colors = {
    black: {
        main: '#303030',
        secondary: '#929596'
    },
    grey: {
        main: '#DFE2E1',
        secondary: '#F7F7F7'
    },
    green: {
        main: '#52E09C'
    },
    blue: {
        main: '#191B8F',
        secondary: '#E8E8F4'
    },
    red: {
        main: '#F23A3A'
    },
    orange: {
        main: '#E66928',
        secondary: '#FCF0E9'
    }
}
