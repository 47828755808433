
// REF - Plateforms.

export const enumPlateformCodeGSMC = "GSMC"
export const enumPlateformCodeLesFurets = "LESFURETS"
export const enumPlateformCodeGraphiste = "GRAPHISTE"
export const enumPlateformCodeCodeur = "CODEUR"
export const enumPlateformCodeRedacteur = "REDACTEUR"
export const enumPlateformCodeTraduc = "TRADUC"
export const enumPlateformCodeAngel = "ANGEL"
export const enumPlateformCodeLPBB = "LPBB"


// REF - Offers.

export const enumOfferSenior = "312"
export const enumOfferGeneration = "512"
export const enumOfferTNS = "539"

export const enumOfferLabels = [
    {
        label: "SENIOR 100% NOUS",
        value: enumOfferSenior
    },
    {
        label: "GENERATION 100% NOUS",
        value: enumOfferGeneration
    },
    {
        label: "ACTIV' SANTE TNS",
        value: enumOfferTNS
    }
]


// HR - Sexes.

export const enumHrSexMan = "MAN"
export const enumHrSexWoman = "WOMAN"

export const enumHrSexes = [
    {
        label: "Homme",
        value: enumHrSexMan
    },
    {
        label: "Femme",
        value: enumHrSexWoman
    }
]


// HR - Civilities.

export const enumHrCivitityMister = "M"
export const enumHrCivitityMadam = "Mme"

export const enumHrCivilities = [
    {
        label: "Madame",
        value: enumHrCivitityMadam
    },
    {
        label: "Monsieur",
        value: enumHrCivitityMister
    }
]


// HR - role.

export const enumHrRoleSubscriber = "SUBSCRIBER"
export const enumHrRoleConjoint = "CONJOINT"
export const enumHrRoleChild = "CHILD"

export const enumHrRoles = [
    {
        label: "Souscripteur",
        value: enumHrRoleSubscriber
    },
    {
        label: "Conjoint",
        value: enumHrRoleConjoint
    },
    {
        label: "Enfant",
        value: enumHrRoleChild
    },
]


// HR - Regimes.

// NOT USED.
/*
export const enumHrRegimes = [
    {
        label: "CPAM",
        value: "01"
    },
    {
        label: "MSA",
        value: "02"
    },
    {
        label: "RSI",
        value: "03"
    },
    {
        label: "SNCF",
        value: "04"
    },
    {
        label: "RATP",
        value: "05"
    },
    {
        label: "ENIM",
        value: "06"
    },
    {
        label: "MINES",
        value: "07"
    },
    {
        label: "CNMSS",
        value: "08"
    },
    {
        label: "CPM Banque de France",
        value: "09"
    },
    {
        label: "CRPCEN",
        value: "10"
    },
    {
        label: "RSAM CCIP",
        value: "12"
    },
    {
        label: "Assemblée Nationale",
        value: "14"
    },
    {
        label: "SENAT",
        value: "15"
    },
    {
        label: "Port Autonome de Bordeaux",
        value: "16"
    },
    {
        label: "Français à l'étranger",
        value: "17"
    },
    {
        label: "Ministère des Anciens Combattants",
        value: "80"
    },
    {
        label: "CAMAC",
        value: "90"
    },
    {
        label: "MGEN",
        value: "91"
    },
    {
        label: "MG",
        value: "92"
    },
    {
        label: "MGP",
        value: "93"
    },
    {
        label: "MFP",
        value: "94"
    },
    {
        label: "MNH",
        value: "95"
    },
    {
        label: "MNAM",
        value: "96"
    },
    {
        label: "SLM",
        value: "99"
    },
]
*/


// HR - Regimes GSMC.

export const enumGsmcRegimeGeneral = "01"
export const enumGsmcRegimeIndep = "03"
export const enumGsmcRegimeAM = "50"

export const enumGsmcRegimes = [
    {
        label: "Régime Général",
        value: enumGsmcRegimeGeneral
    }, {
        label: "Régime des Indépendants",
        value: enumGsmcRegimeIndep
    }, {
        label: "Régime Alsace-Moselle",
        value: enumGsmcRegimeAM
    }
]


// Contract - Periodicity.

export const enumContractPeriodicityMonthly = "MONTHLY"
export const enumContractPeriodicityAnnually = "ANNUALLY"

export const enumContractPeriodicities = [
    {
        label: "Mensuelle",
        value: enumContractPeriodicityMonthly
    },
    {
        label: "Annuelle",
        value: enumContractPeriodicityAnnually
    }
]


// ContractBilling - Type transaction.

export const enumContractBillingTransactionTypeAll = "ALL"
export const enumContractBillingTransactionTypePayment = "PAYMENT"
export const enumContractBillingTransactionTypeIncome = "INCOME"

export const enumContractBillingTransactionTypes = [
    {
        label: "TOUS",
        value: enumContractBillingTransactionTypeAll
    },
    {
        label: "Paiement",
        value: enumContractBillingTransactionTypePayment
    },
    {
        label: "Recette",
        value: enumContractBillingTransactionTypeIncome
    }
]


// FormDocument - Type document.

export const enumFormDocumentTypeANIP = "ANIP"
export const enumFormDocumentTypeAttestationCPAM = "ATTESTATION_CPAM"
export const enumFormDocumentTypeBulletin = "BULLETIN"
export const enumFormDocumentTypeCGAssistance = "CG_ASSISTANCE"
export const enumFormDocumentTypeCniRecto = "CNI_RECTO"
export const enumFormDocumentTypeCniVerso = "CNI_VERSO"
export const enumFormDocumentTypeGuideSubscriber = "GUIDE_SUBSCRIBER"
export const enumFormDocumentTypeIPID = "IPID"
export const enumFormDocumentTypeIPIDS = "IPIDS"
export const enumFormDocumentTypeKbis = "KBIS"
export const enumFormDocumentTypeMandatPrelevement = "MANDAT_PRELEVEMENT"
export const enumFormDocumentTypeMedecinDirectBrochure = "MEDECIN_DIRECT_BROCHURE"
export const enumFormDocumentTypeRecueilBesoin = "RECUEIL_BESOIN"
export const enumFormDocumentTypeRIB = "RIB"
export const enumFormDocumentTypeRM = "RM"
export const enumFormDocumentTypeRMS = "RMS"
export const enumFormDocumentTypeSubscriptionCertificat = "SUBSCRIPTION_CERTIFICAT"
export const enumFormDocumentTypeTG = "TG"


export const enumFormDocumentCriteres = [
    {
        selector: enumFormDocumentTypeAttestationCPAM,
        criteres: [
            "Ce document est une attestation CPAM"
        ]
    },
    {
        selector: enumFormDocumentTypeCniRecto,
        criteres: [
            "Ce document est une pièce d'identité valide"
        ]
    },
    {
        selector: enumFormDocumentTypeCniVerso,
        criteres: [
            "Ce document est une pièce d'identité valide"
        ]
    },
    {
        selector: enumFormDocumentTypeKbis,
        criteres: [
            "Ce document est un Kbis valide"
        ]
    },
    {
        selector: enumFormDocumentTypeRIB,
        criteres: [
            "Ce document est un RIB valide"
        ]
    }
]

export const enumFormDocumentTypes = [
    {
        label: "ANIP",
        icon: "📜",
        value: enumFormDocumentTypeANIP
    },
    {
        label: "Attestation CPAM",
        icon: "📜",
        value: enumFormDocumentTypeAttestationCPAM
    },
    {
        label: "Contrat",
        icon: "✍️",
        value: enumFormDocumentTypeBulletin
    },
    {
        label: "Conditions Générales Assistance",
        icon: "📖",
        value: enumFormDocumentTypeCGAssistance
    },
    {
        label: "Pièce d'identité - Recto",
        icon: "🪪",
        value: enumFormDocumentTypeCniRecto
    },
    {
        label: "Pièce d'identité - Verso",
        icon: "🪪",
        value: enumFormDocumentTypeCniVerso
    },
    {
        label: "Guide Santé Adhérent",
        icon: "⚕️",
        value: enumFormDocumentTypeGuideSubscriber
    },
    {
        label: "Document d'information",
        icon: "📣",
        value: enumFormDocumentTypeIPID
    },
    {
        label: "Document d'information Surco",
        icon: "📣",
        value: enumFormDocumentTypeIPIDS
    },
    {
        label: "Kbis",
        icon: "🏢",
        value: enumFormDocumentTypeKbis
    },
    {
        label: "Mandat SEPA",
        icon: "🏦",
        value: enumFormDocumentTypeMandatPrelevement
    },
    {
        label: "Plaquette Médecin Direct",
        icon: "👨‍⚕️",
        value: enumFormDocumentTypeMedecinDirectBrochure
    },
    {
        label: "Recueil des besoins",
        icon: "✔️",
        value: enumFormDocumentTypeRecueilBesoin
    },
    {
        label: "RIB",
        icon: "🏦",
        value: enumFormDocumentTypeRIB
    },
    {
        label: "Règlement Mutualiste",
        icon: "📖",
        value: enumFormDocumentTypeRM
    },
    {
        label: "Règlement Mutualiste Surco",
        icon: "📖",
        value: enumFormDocumentTypeRMS
    },
    {
        label: "Certificat d'Adhésion",
        icon: "🎓",
        value: enumFormDocumentTypeSubscriptionCertificat
    },
    {
        label: "Tableau de garanties",
        icon: "🩹",
        value: enumFormDocumentTypeTG
    }
]

// BO - Contact methods.

export const enumContactMethodForm = "FORM"
export const enumContactMethodRedirect = "REDIRECT"

export const enumContactMethods = [
    {
        label: "Formulaire",
        value: enumContactMethodForm
    },
    {
        label: "Redirection",
        value: enumContactMethodRedirect
    }
]

export const enumSmtpMethodOffice = "OFFICE"
export const enumSmtpMethodMailjet = "MAILJET"
export const enumSmtpMethodBrevo = "BREVO"

export const enumSmtpMethod = [
    {
        label: "Office 365",
        value: enumSmtpMethodOffice
    },
    {
        label: "Mailjet",
        value: enumSmtpMethodMailjet
    },
    {
        label: "Brevo",
        value: enumSmtpMethodBrevo
    }
]

// BO - Estimation generation mode
export const enumEstimationModeHenner = "HENNER"

export const enumEstimationModes = [
    {
        label: "Henner",
        value: enumEstimationModeHenner
    }
]

// BO - Tarification methods
export const enumTarificationModeHenner = "HENNER"
export const enumTarificationModeGSMC = "GSMC"

export const enumTarificationModes = [
    {
        label: "Henner",
        value: enumTarificationModeHenner
    },
    {
        label: "GSMC",
        value: enumTarificationModeGSMC
    }
]
