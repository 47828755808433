
import { forwardRef, Ref, useEffect, useImperativeHandle } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik'

import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fr from 'date-fns/locale/fr';
import { frFR } from '@mui/x-date-pickers/locales'

import { FieldLabel } from '../Field/FieldLabel';

import { IContract } from '../../model/Contract.model';
import { messageFieldRequired } from '../../utils/messages';
import { convertUTCDateToLocalDate } from '../../utils/dateUtils';


interface Props {
    disabled?: boolean;
    contract: IContract;
    send: (contract: IContract) => void;
    save: (contract: IContract) => void;
}


const dateToday = new Date();

const dateTomorrow = new Date(dateToday);
dateTomorrow.setDate(dateTomorrow.getDate() + 1);

const dateStartMax = new Date(dateToday);
dateStartMax.setFullYear(dateStartMax.getFullYear() + 1);


const validationSchema = yup.object({
    dateStart: yup
        .date()
        .typeError("Date non valide")
        .nullable()
        .min(new Date(), `Vous ne pouvez pas souscrire avant le ${dateTomorrow.getDate().toString().padStart(2, '0')}/${(dateTomorrow.getMonth() + 1).toString().padStart(2, '0')}/${dateTomorrow.getFullYear()}`)
        .max(dateStartMax, `Vous ne pouvez pas souscrire après le ${dateStartMax.getDate().toString().padStart(2, '0')}/${(dateStartMax.getMonth() + 1).toString().padStart(2, '0')}/${dateStartMax.getFullYear()}`)
        .required(messageFieldRequired)
});


export const FormContract = forwardRef((props: Props, ref: Ref<any>) => {

    useImperativeHandle(ref, () => ({
        handleSubmit() {
            formik.validateForm();

            formik.handleSubmit();

            if (formik.isValid)
                return true;

            return false;
        },
        leave() {
            formik.validateForm().then((errors: any) => {
                if (!errors.dateStart) send();
            });
        }
    }));


    // Get the contract: IContract from the form fields.
    const getContractFromForm = () => {
        let contract: IContract = { ...props.contract };

        contract.dateStart = formik.values.dateStart === null ? undefined : convertUTCDateToLocalDate(formik.values.dateStart);

        return contract;
    };

    const formik = useFormik({
        initialValues: {
            dateStart: props.contract.dateStart === undefined ? null : new Date(props.contract.dateStart)
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            save();
        }
    });

    const save = () => {
        props.save(getContractFromForm());
    }

    const send = () => {
        props.send(getContractFromForm());
    }

    useEffect(() => {
        formik.validateForm().then((errors: any) => {
            if (!errors.dateStart) save()
        })
    }, [formik.values.dateStart])


    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                container
                spacing={2}
                sx={{ textAlign: 'left' }} >

                <Grid
                    item
                    xs={12} >
                    <FormLabel error={formik.touched.dateStart && Boolean(formik.errors.dateStart)} >
                        <FieldLabel label="Début du contrat" />
                    </FormLabel>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={fr}
                        localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <DatePicker
                            disabled={props.disabled}
                            value={formik.values.dateStart}
                            onChange={(value: any) => formik.setFieldValue("dateStart", value)}
                            localeText={{
                                toolbarTitle: "Sélectionnez une date",
                                okButtonLabel: "Valider"
                            }}
                            minDate={dateTomorrow}
                            maxDate={dateStartMax}
                            format='dd/MM/yyyy'
                            slotProps={{
                                textField: {
                                    sx: {
                                        width: '100%'
                                    },
                                    onBlur: (e) => formik.setFieldTouched("dateStart"),
                                    error: Boolean(formik.errors.dateStart),
                                    helperText: <>{formik.errors.dateStart}</>,
                                },
                            }} />
                    </LocalizationProvider>

                    {!formik.errors.dateStart &&
                        formik.values.dateStart !== null &&
                        formik.values.dateStart !== undefined &&
                        <FormHelperText
                            sx={{
                                ml: 1
                            }}>
                            {new Date(formik.values.dateStart).getFullYear() === dateToday.getFullYear() + 1 &&
                                (dateToday.getMonth() < 8 || (dateToday.getMonth() === 8 && dateToday.getDate() < 15))
                                ? "Ce tarif ne tient pas compte des potentielles augmentations tarifaires au 1er janvier pour l’année à venir."
                                : "Ce tarif est susceptible d’évoluer au 1er janvier de l’année à venir."}
                        </FormHelperText>}
                </Grid>
            </Grid>
        </form>
    )
})
