export const colors = {
    black: {
        main: '#212121',
        secondary: '#6C757D'
    },
    grey: {
        main: '#DFE2E1',
        secondary: '#F7F7F7'
    },
    green: {
        main: '#14BD96',
        secondary: '#0D9488'
    },
    turquoise: {
        main: '#0DCAF0'
    },
    blue: {
        main: '#4190DD',
        secondary: '#d8e8f8'
    },
    purple: {
        main: '#7F68D5',
        secondary: '#7F68D5'
    },
    pink: {
        main: '#D63384'
    },
    red: {
        main: '#D66648'
    },
    orange: {
        main: '#FF6944',
        secondary: '#FEAA16',
        light: '#FFB3A1'
    }
}
