export interface IWarrantiesSection {
    sectionTitle: string,
    order: number,
    comment?: string,
    subSections: IWarantiesSubSections[]
}

export interface IWarantiesSubSections {
    subSectionTitle?: string,
    order: number,
    warranties: IWarranties[],
}

export interface IWarranties {
    label: string,
    order: number,
    formulas: IWarrantiesDetails[],
}

export interface IWarrantiesDetails {
    formulaCode: string,
    amounts: IWarrantiesAmount[],
}

export interface IWarrantiesAmount {
    amount: number,
    amountType: WarrantyAmountType,
    amountAcronym: string,
    order: number,
    mr: boolean,
    description: string,
}

export interface IWarrantiesAcronyms {
    acronym: string,
    description: string,
}

export enum WarrantyAmountType {
    CURRENCY = "CURRENCY",
    PERCENTAGE_BR = "PERCENTAGE_BR",
    PERCENTAGE_FR = "PERCENTAGE_FR",
    PERCENTAGE_PLV = "PERCENTAGE_PLV",
    PERCENTAGE = "PERCENTAGE",
}
