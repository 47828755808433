
import { forwardRef, Ref, useState } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik'

import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import { FieldLabel } from '../Field/FieldLabel';

import { patchFormPlateform } from '../../api/Form.api';
import { IFormPatchPlateform } from '../../model/Form.model';
import { CloseFullIcon } from '../Icon/CloseFullIcon';
import { messageFieldRequired } from '../../utils';

interface Props {
    plateformStyle: any;
    uuid: string;
    code?: string;
    send?: () => void
}

const validationSchema = yup.object({
    code: yup.string()
});


export const FormPlateformCode = forwardRef((props: Props, ref: Ref<any>) => {

    const buttonSubmitStyle: any = props.plateformStyle.components.Button.submit;
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            code: props.code
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (!values.code) {
                formik.setFieldError('code', messageFieldRequired);
                return;
            }
            const payload: IFormPatchPlateform = {
                plateformCode: values.code
            }
            patchFormPlateform(props.uuid, payload)
                .then(response => {
                    const status = (response as any).status;
                    if (status === 404) {
                        formik.setFieldError('code', 'Code partenaire inconnu');
                        return;
                    }

                    props.send?.();
                    setIsEditing(false);
                })
                .catch(e => {
                })
        }
    });


    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                container
                spacing={2}
                sx={{ textAlign: 'left' }} >

                <Grid
                    item
                    xs={12} >
                    <FormLabel error={formik.touched.code && Boolean(formik.errors.code)} >
                        <FieldLabel label="Code partenaire" />
                    </FormLabel>
                    <TextField
                        fullWidth
                        disabled={!isEditing}
                        variant="outlined"
                        id="code"
                        name="code"
                        value={formik.values.code}
                        onChange={(e) => {
                            let v = e.target.value.toUpperCase()
                            formik.setFieldValue('code', v);
                            formik.setFieldTouched('code');
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched.code && Boolean(formik.errors.code)}
                        helperText={formik.touched.code && formik.errors.code}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isEditing ?
                                        <Stack
                                            direction='row'
                                            sx={{
                                                mr: -1.5
                                            }}>
                                            <Tooltip title='Annuler' placement='top'>
                                                <IconButton
                                                    sx={{
                                                        py: 0.5,
                                                        lineHeight: 'none',
                                                        color: 'white',
                                                        fontSize: '1rem'
                                                    }}
                                                    onClick={() => {
                                                        formik.setFieldValue('code', props.code);
                                                        setIsEditing(false);
                                                    }}>
                                                    <CloseFullIcon width='24' height='24' color={props.plateformStyle.colors.black.main} />
                                                </IconButton>
                                            </Tooltip>
                                            <Button
                                                variant="contained"
                                                color={buttonSubmitStyle.color}
                                                sx={{
                                                    py: 0.5,
                                                    lineHeight: 'none',
                                                    color: 'white',
                                                    fontSize: '1rem'
                                                }}
                                                onClick={() => formik.handleSubmit()}>
                                                Valider
                                            </Button>
                                        </Stack>
                                        :
                                        <Button
                                            sx={{
                                                mr: -1.5,
                                                py: 0.5,
                                                lineHeight: 'none',
                                                fontSize: '1rem',
                                                color: 'white',
                                                backgroundColor: props.plateformStyle.colors.black.main,
                                                ':hover': {
                                                    backgroundColor: 'black'
                                                }
                                            }}
                                            onClick={() => setIsEditing(true)}>
                                            Modifier
                                        </Button>
                                    }
                                </InputAdornment>
                            )
                        }} />
                </Grid>
            </Grid>
        </form>
    )
})
