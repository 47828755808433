import { useEffect, useState } from 'react';

import { FormikErrors, useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TableChartIcon from '@mui/icons-material/TableChart';

import { FormRadioButton } from './FormRadioButton';
import { FieldLabel } from '../Field/FieldLabel';

import { IPlateforme } from '../../model';
import { IPlateformeSettingsEstimation } from '../../model/PlateformeSettings.model';
import { updatePlateformeSettingsEstimation } from '../../api/PlateformeSettings.api';

import { colors } from '../../static/themes/gsmc/colors';
import { enumEstimationModeHenner, enumEstimationModes, messageFieldRequired } from '../../utils';


interface Props {
    plateforme: IPlateforme;
    settings: IPlateformeSettingsEstimation;
    send: (settings: IPlateformeSettingsEstimation) => void;
    handleError: (message: string) => void;
}


export const FormPlateformeSettingsEstimation = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const validationSchema = yup.object({
        generationMode: yup.string().required(messageFieldRequired),
        enableEstimation: yup.boolean().required(messageFieldRequired),
        sendNotificationEmail: yup.boolean().required(messageFieldRequired),
        notificationEmail: yup.string().email("Email non valide").nullable()
    })

    const formik = useFormik({
        initialValues: {
            generationMode: enumEstimationModeHenner,
            enableEstimation: props.settings.enableEstimation,
            notificationEmail: props.settings.notificationEmail,
            sendNotificationEmail: false
        },
        validationSchema: validationSchema,
        validate: (values: any) => {
            let errors: FormikErrors<any> = {};

            if (values.sendNotificationEmail &&
                (!values.notificationEmail || values.notificationEmail === null))
                errors.notificationEmail = messageFieldRequired;

            return errors;
        },
        onSubmit: (values) => {
            const toSend: IPlateformeSettingsEstimation = {
                id: props.settings.id,
                enableEstimation: values.enableEstimation,
                sendNotificationEmail: values.sendNotificationEmail,
                notificationEmail: values.notificationEmail
            }
            updatePlateformeSettingsEstimation(props.settings.id, toSend)
            .then((response: IPlateformeSettingsEstimation) => {
                props.send(response);
                setSuccess(true);
            })
            .catch(exception => {
                props.handleError("Erreur lors de l'enregistrement des paramètres des devis.");
                setError(true);
            });
        }
    });

    useEffect(() => {
        if (!success)
            return;

        setTimeout(function () {
            setSuccess(false);
        }, 2000);
    }, [success]);

    useEffect(() => {
        if (!error)
            return;

        setTimeout(function () {
            setError(false);
        }, 2000);
    }, [error]);


    return (
        <form onSubmit={formik.handleSubmit} >
            <Grid
                container
                spacing={3}
                sx={{
                    textAlign: 'left'
                }}>

                <Grid
                    item
                    xs={12}>
                    <Typography
                        variant='h2'>
                        <TableChartIcon
                            fontSize='medium'
                            sx={{
                                verticalAlign: 'bottom',
                                mb: 0.75,
                                mr: 1
                            }} />
                        Devis
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Typography
                        variant='h3'>
                        Parcours de souscription
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Grid
                        container
                        spacing={2}>

                        <Grid
                            item
                            xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.enableEstimation}
                                        onChange={(e, value) => formik.setFieldValue("enableEstimation", value)} />
                                }
                                label={
                                    <>
                                    <Typography
                                        component='span'
                                        fontWeight={500}
                                        sx={{mt: 0.5}}>
                                        Activer la création de devis
                                    </Typography>
                                    <Typography
                                        variant='caption'
                                        component='div'
                                        fontSize='0.9rem' >
                                        Le souscripteur pourra créer des devis dans le parcours de souscription
                                    </Typography>
                                    </>
                                }
                                labelPlacement='end'
                                sx={{
                                    alignItems: 'center'
                                }}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Divider />
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Typography
                        variant='h3'>
                        Notification
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formik.values.sendNotificationEmail}
                                onChange={(e, value) => formik.setFieldValue("sendNotificationEmail", value)} />
                        }
                        label={
                            <>
                            <Typography
                                component='span'
                                fontWeight={500}
                                sx={{mt: 0.5}}>
                                Envoyer une notification lors de la création d'un devis
                            </Typography>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Les devis créés seront envoyés par email à l'adresse ci-dessous
                            </Typography>
                            </>
                        }
                        labelPlacement='end'
                        sx={{
                            alignItems: 'center'
                        }}/>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <FormLabel error={formik.touched.notificationEmail && Boolean(formik.errors.notificationEmail)} >
                        <FieldLabel
                            label="Email de notification"
                            fontWeight={500}
                            isRequired={
                                Boolean(formik.values.sendNotificationEmail)
                            }
                            mb={false} />
                    </FormLabel>
                    <Typography
                        variant='caption'
                        component='div'
                        fontSize='0.9rem'
                        sx={{
                            mb: 0.75
                        }} >
                        L'email de notification recevra une copie des devis créés sur le tunnel
                    </Typography>
                    <TextField
                        fullWidth
                        id="notificationEmail"
                        name="notificationEmail"
                        value={formik.values.notificationEmail}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.notificationEmail && Boolean(formik.errors.notificationEmail)}
                        helperText={formik.touched.notificationEmail && formik.errors.notificationEmail}
                        sx={{
                            maxWidth: '500px'
                        }} />
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Divider />
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Typography
                        variant='h3'>
                        Document
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <FormLabel error={formik.touched.generationMode && Boolean(formik.errors.generationMode)} >
                        <FieldLabel
                            label="Méthode de génération"
                            fontWeight={500}
                            isRequired
                            mb={false} />
                    </FormLabel>
                    <Typography
                        variant='caption'
                        component='div'
                        fontSize='0.9rem'
                        sx={{
                            mb: 0.75
                        }} >
                        Méthode de génération du devis utilisée (uniquement Henner)
                    </Typography>
                    <Grid
                        container
                        spacing={2} >
                        {enumEstimationModes.map((_, id) => {
                            return <Grid
                                item
                                lg={2}
                                md={3}
                                sm={4}
                                xs={12} >
                                <FormRadioButton
                                    code={_.value}
                                    selected={formik.values.generationMode === _.value}
                                    color={colors.blue.main}
                                    onClick={(value) => {
                                        formik.setFieldValue("generationMode", value);
                                        formik.setFieldTouched("generationMode");
                                    }} >
                                    {_.label}
                                </FormRadioButton>
                            </Grid>
                        })}
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Button
                        type='submit'
                        sx={{
                            px: 2,
                            py: 1,
                            color: 'white',
                            backgroundColor: colors.black.main,
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Enregistrer
                    </Button>

                    <Typography
                        component='span'
                        sx={{
                            display: success ? 'inline' : 'none',
                            ml: 2,
                            color: colors.green.main
                        }}>
                        <CheckCircleIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Enregistrement réussi
                        </Typography>
                    </Typography>

                    <Typography
                        component='span'
                        sx={{
                            display: error ? 'inline' : 'none',
                            ml: 2,
                            color: colors.red.main
                        }}>
                        <CancelIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Erreur lors de l'enregistrement
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </form>
    )
}
