import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ArticleIcon from '@mui/icons-material/Article';
import CommentIcon from '@mui/icons-material/Comment';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EmailIcon from '@mui/icons-material/Email';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FolderIcon from '@mui/icons-material/Folder';
import PaletteIcon from '@mui/icons-material/Palette';
import LanguageIcon from '@mui/icons-material/Language';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import TableChartIcon from '@mui/icons-material/TableChart';
import WebhookIcon from '@mui/icons-material/Webhook';

import { CardError } from '../../../components/Card/CardError';
import { FormPlateformeSettingsBanking } from '../../../components/Form/FormPlateformeSettingsBanking';
import { FormPlateformeSettingsContact } from '../../../components/Form/FormPlateformeSettingsContact';
import { FormPlateformeSettingsDocument } from '../../../components/Form/FormPlateformeSettingsDocument';
import { FormPlateformeSettingsSmtp } from '../../../components/Form/FormPlateformeSettingsSmtp';
import { FormPlateformeSettingsSubscription } from '../../../components/Form/FormPlateformeSettingsSubscription';
import { FormPlateformeSettingsSupport } from '../../../components/Form/FormPlateformeSettingsSupport';
import { FormPlateformeSettingsStyle } from '../../../components/Form/FormPlateformeSettingsStyle';
import { CloseFullIcon } from '../../../components/Icon/CloseFullIcon';

import { getPlateformeSettings } from '../../../api/PlateformeSettings.api';

import { IPlateforme } from '../../../model/Plateforme.model';
import {
    IPlateformeSettings,
    IPlateformeSettingsBanking,
    IPlateformeSettingsContact,
    IPlateformeSettingsDocument,
    IPlateformeSettingsFraud,
    IPlateformeSettingsTarification,
    IPlateformeSettingsSmtp,
    IPlateformeSettingsStyle,
    IPlateformeSettingsSubscription,
    IPlateformeSettingsTemplateDocument,
    IPlateformeSettingsTemplateEmail,
    IPlateformeSettingsWebhook,
    IPlateformeSettingsEstimation
} from '../../../model/PlateformeSettings.model';

import { colors } from '../../../static/themes/gsmc/colors';
import { FormPlateformeSettingsTemplateEmail } from '../../../components/Form/FormPlateformeSettingsTemplateEmail';
import { FormPlateformeSettingsTemplateDocument } from '../../../components/Form/FormPlateformeSettingsTemplateDocument';
import AppBarHeader from '../../../components/AppBar/AppBarHeader';
import { FormPlateformeSettingsFraud } from '../../../components/Form/FormPlateformeSettingsFraud';
import { FormPlateformeSettingsTarification } from '../../../components/Form/FormPlateformeSettingsTarification';
import { FormPlateformeSettingsWebhook } from "../../../components/Form/FormPlateformeSettingsWebhook";
import { FormPlateformeSettingsEstimation } from '../../../components/Form/FormPlateformeSettingsEstimation';


export const PlateformeSettings = () => {

    // URL parameters.
    const urlPlateformeCode = useParams<{ code: string }>().code

    const theme = useTheme();
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const [isSnackbarDisplayed, setIsSnackbarDisplayed] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>();

    const [tabValue, setTabValue] = useState<string>("90");

    const [plateforme, setPlateforme] = useState<IPlateforme | null>();
    const [settingsBanking, setSettingsBanking] = useState<IPlateformeSettingsBanking>();
    const [settingsContact, setSettingsContact] = useState<IPlateformeSettingsContact>();
    const [settingsDocument, setSettingsDocument] = useState<IPlateformeSettingsDocument>();
    const [settingsEstimation, setSettingsEstimation] = useState<IPlateformeSettingsEstimation>();
    const [settingsFraud, setSettingsFraud] = useState<IPlateformeSettingsFraud>();
    const [settingsTarification, setSettingsTarification] = useState<IPlateformeSettingsTarification>();
    const [settingsSmtp, setSettingsSmtp] = useState<IPlateformeSettingsSmtp>();
    const [settingsSubscription, setSettingsSubscription] = useState<IPlateformeSettingsSubscription>();
    const [settingsWebhook, setSettingsWebhook] = useState<IPlateformeSettingsWebhook>();
    const [settingsStyle, setSettingsStyle] = useState<IPlateformeSettingsStyle>();
    const [settingsTemplateDocument, setSettingsTemplateDocument] = useState<IPlateformeSettingsTemplateDocument>();
    const [settingsTemplateEmail, setSettingsTemplateEmail] = useState<IPlateformeSettingsTemplateEmail>();

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarDisplayed(false);
    };

    useEffect(() => {
        if (!urlPlateformeCode)
            return;

        getPlateformeSettings(urlPlateformeCode)
            .then((response: IPlateformeSettings | any) => {
                if (response.status && response.status !== 200) {
                    setPlateforme(null);
                    return;
                }

                setPlateforme(response.plateforme);
                setSettingsBanking(response.settingsBanking);
                setSettingsContact(response.settingsContact);
                setSettingsDocument(response.settingsDocument);
                setSettingsEstimation(response.settingsEstimation);
                setSettingsFraud(response.settingsFraud);
                setSettingsSmtp(response.settingsSmtp);
                setSettingsTarification(response.settingsTarification)
                setSettingsSubscription(response.settingsSubscription);
                setSettingsStyle(response.settingsStyle);
                setSettingsTemplateDocument(response.settingsTemplateDocument);
                setSettingsTemplateEmail(response.settingsTemplateEmail);
                setSettingsWebhook(response.settingsWebhook);
                document.title = `BO - Plateforme ${response.plateforme.code}`;
            })
            .catch((exception) => {
                setPlateforme(null);
            })
    }, [urlPlateformeCode])


    return (
        <>
        <AppBarHeader
            items={[
                {type: "TITLE", icon: <LanguageIcon />},
                {type: "LINK", label: "Plateforme", url: "/bo/plateforme"},
                {type: "LINK", label: urlPlateformeCode, url: "/bo/plateforme"},
                {type: "TITLE", label: "Paramètres"}
            ]}
            />

        <Box
            sx={{
                overflow:"auto",
                p: 2
            }}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}>

                {plateforme === undefined ?
                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            height: '150px',
                            textAlign: 'center'
                        }}>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                height: '100%'
                            }} >
                            <Grid
                                item>
                                <Typography
                                    variant='subtitle1'>
                                    Chargement des paramètres de la plateforme en cours...
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                : plateforme === null ?
                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            height: '150px',
                            textAlign: 'center'
                        }}>
                        <CardError
                            title={`Une erreur est survenue lors de la récupération de la plateforme “${urlPlateformeCode}”`}
                            description="Veuillez réessayer ultérieurement" />
                    </Card>
                </Grid>

                :
                <>
                <Grid
                    item
                    xs={12} >
                    <Card>
                        <TabContext value={tabValue}>
                            <Grid
                                container
                                spacing={0}
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                sx={{
                                    height: '100%'
                                }} >
                                <Grid
                                    item
                                    md={2}
                                    sm={3}
                                    xs={12}>
                                    <Tabs
                                        value={tabValue}
                                        orientation={screenSizeUpSM ? "vertical" : "horizontal"}
                                        onChange={(e, value) => setTabValue(value)}
                                        variant="scrollable"
                                        allowScrollButtonsMobile
                                        sx={{
                                            textAlign: 'left'
                                        }}>

                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'
                                                    sx={{
                                                        textAlign: 'left',
                                                        color: colors.black.secondary
                                                    }}>
                                                    Général
                                                </Typography>}
                                            disabled
                                            sx={{
                                                display: {
                                                    sm: 'block',
                                                    xs: 'none'
                                                }
                                            }} />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'>
                                                    <PaletteIcon
                                                        fontSize='small'
                                                        sx={{
                                                            verticalAlign: 'bottom',
                                                            mb: 0.25,
                                                            mr: 0.5
                                                        }} />
                                                    <Typography
                                                        component='span'
                                                        fontWeight={tabValue === "90" ? 700 : 400}
                                                        sx={{
                                                            display: {
                                                                sm: 'inline',
                                                                xs: 'none'
                                                            }
                                                        }}>
                                                        Apparence
                                                    </Typography>
                                                </Typography>}
                                            value="90" />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'>
                                                    <CommentIcon
                                                        fontSize='small'
                                                        sx={{
                                                            verticalAlign: 'bottom',
                                                            mb: 0.25,
                                                            mr: 0.5
                                                        }} />
                                                    <Typography
                                                        component='span'
                                                        fontWeight={tabValue === "100" ? 700 : 400}
                                                        sx={{
                                                            display: {
                                                                sm: 'inline',
                                                                xs: 'none'
                                                            }
                                                        }}>
                                                        Contact
                                                    </Typography>
                                                </Typography>}
                                            value="100" />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'>
                                                    <ContactSupportIcon
                                                        fontSize='small'
                                                        sx={{
                                                            verticalAlign: 'bottom',
                                                            mb: 0.25,
                                                            mr: 0.5
                                                        }} />
                                                    <Typography
                                                        component='span'
                                                        fontWeight={tabValue === "110" ? 700 : 400}
                                                        sx={{
                                                            display: {
                                                                sm: 'inline',
                                                                xs: 'none'
                                                            }
                                                        }}>
                                                        Support
                                                    </Typography>
                                                </Typography>}
                                            value="110" />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'
                                                    sx={{
                                                        textAlign: 'left',
                                                        color: colors.black.secondary
                                                    }}>
                                                    Documents
                                                </Typography>}
                                            disabled
                                            sx={{
                                                display: {
                                                    sm: 'block',
                                                    xs: 'none'
                                                },
                                                borderTop: 1,
                                                borderColor: colors.grey.main
                                            }} />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'>
                                                    <ArticleIcon
                                                        fontSize='small'
                                                        sx={{
                                                            verticalAlign: 'bottom',
                                                            mb: 0.25,
                                                            mr: 0.5
                                                        }} />
                                                    <Typography
                                                        component='span'
                                                        fontWeight={tabValue === "170" ? 700 : 400}
                                                        sx={{
                                                            display: {
                                                                sm: 'inline',
                                                                xs: 'none'
                                                            }
                                                        }}>
                                                        PDF
                                                    </Typography>
                                                </Typography>}
                                            value="170" />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'>
                                                    <EmailIcon
                                                        fontSize='small'
                                                        sx={{
                                                            verticalAlign: 'bottom',
                                                            mb: 0.25,
                                                            mr: 0.5
                                                        }} />
                                                    <Typography
                                                        component='span'
                                                        fontWeight={tabValue === "180" ? 700 : 400}
                                                        sx={{
                                                            display: {
                                                                sm: 'inline',
                                                                xs: 'none'
                                                            }
                                                        }}>
                                                        Mails
                                                    </Typography>
                                                </Typography>}
                                            value="180" />

                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'
                                                    sx={{
                                                        textAlign: 'left',
                                                        color: colors.black.secondary
                                                    }}>
                                                    Formulaire
                                                </Typography>}
                                            disabled
                                            sx={{
                                                display: {
                                                    sm: 'block',
                                                    xs: 'none'
                                                },
                                                borderTop: 1,
                                                borderColor: colors.grey.main
                                            }} />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'>
                                                    <TableChartIcon
                                                        fontSize='small'
                                                        sx={{
                                                            verticalAlign: 'bottom',
                                                            mb: 0.25,
                                                            mr: 0.5
                                                        }} />
                                                    <Typography
                                                        component='span'
                                                        fontWeight={tabValue === "200" ? 700 : 400}
                                                        sx={{
                                                            display: {
                                                                sm: 'inline',
                                                                xs: 'none'
                                                            }
                                                        }}>
                                                        Devis
                                                    </Typography>
                                                </Typography>}
                                            value="200" />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'>
                                                    <FolderIcon
                                                        fontSize='small'
                                                        sx={{
                                                            verticalAlign: 'bottom',
                                                            mb: 0.25,
                                                            mr: 0.5
                                                        }} />
                                                    <Typography
                                                        component='span'
                                                        fontWeight={tabValue === "201" ? 700 : 400}
                                                        sx={{
                                                            display: {
                                                                sm: 'inline',
                                                                xs: 'none'
                                                            }
                                                        }}>
                                                        Documents
                                                    </Typography>
                                                </Typography>}
                                            value="201" />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'>
                                                    <CreditCardIcon
                                                        fontSize='small'
                                                        sx={{
                                                            verticalAlign: 'bottom',
                                                            mb: 0.25,
                                                            mr: 0.5
                                                        }} />
                                                    <Typography
                                                        component='span'
                                                        fontWeight={tabValue === "210" ? 700 : 400}
                                                        sx={{
                                                            display: {
                                                                sm: 'inline',
                                                                xs: 'none'
                                                            }
                                                        }}>
                                                        Bancaire
                                                    </Typography>
                                                </Typography>}
                                            value="210" />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'>
                                                    <TroubleshootIcon
                                                        fontSize='small'
                                                        sx={{
                                                            verticalAlign: 'bottom',
                                                            mb: 0.25,
                                                            mr: 0.5
                                                        }} />
                                                    <Typography
                                                        component='span'
                                                        fontWeight={tabValue === "220" ? 700 : 400}
                                                        sx={{
                                                            display: {
                                                                sm: 'inline',
                                                                xs: 'none'
                                                            }
                                                        }}>
                                                        Fraude
                                                    </Typography>
                                                </Typography>}
                                            value="220" />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'
                                                    sx={{
                                                        textAlign: 'left',
                                                        color: colors.black.secondary
                                                    }}>
                                                    Configuration
                                                </Typography>}
                                            disabled
                                            sx={{
                                                display: {
                                                    sm: 'block',
                                                    xs: 'none'
                                                },
                                                borderTop: 1,
                                                borderColor: colors.grey.main
                                            }} />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'>
                                                    <EmojiEventsIcon
                                                        fontSize='small'
                                                        sx={{
                                                            verticalAlign: 'bottom',
                                                            mb: 0.25,
                                                            mr: 0.5
                                                        }} />
                                                    <Typography
                                                        component='span'
                                                        fontWeight={tabValue === "300" ? 700 : 400}
                                                        sx={{
                                                            display: {
                                                                sm: 'inline',
                                                                xs: 'none'
                                                            }
                                                        }}>
                                                        Souscription
                                                    </Typography>
                                                </Typography>}
                                            value="300" />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'>
                                                    <AlternateEmailIcon
                                                        fontSize='small'
                                                        sx={{
                                                            verticalAlign: 'bottom',
                                                            mb: 0.25,
                                                            mr: 0.5
                                                        }} />
                                                    <Typography
                                                        component='span'
                                                        fontWeight={tabValue === "310" ? 700 : 400}
                                                        sx={{
                                                            display: {
                                                                sm: 'inline',
                                                                xs: 'none'
                                                            }
                                                        }}>
                                                        SMTP
                                                    </Typography>
                                                </Typography>}
                                            value="310" />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'>
                                                    <MonetizationOn
                                                        fontSize='small'
                                                        sx={{
                                                            verticalAlign: 'bottom',
                                                            mb: 0.25,
                                                            mr: 0.5
                                                        }} />
                                                    <Typography
                                                        component='span'
                                                        fontWeight={tabValue === "320" ? 700 : 400}
                                                        sx={{
                                                            display: {
                                                                sm: 'inline',
                                                                xs: 'none'
                                                            }
                                                        }}>
                                                        Tarification
                                                    </Typography>
                                                </Typography>}
                                            value="320" />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'>
                                                    <WebhookIcon
                                                        fontSize='small'
                                                        sx={{
                                                            verticalAlign: 'bottom',
                                                            mb: 0.25,
                                                            mr: 0.5
                                                        }} />
                                                    <Typography
                                                        component='span'
                                                        fontWeight={tabValue === "330" ? 700 : 400}
                                                        sx={{
                                                            display: {
                                                                sm: 'inline',
                                                                xs: 'none'
                                                            }
                                                        }}>
                                                        Webhook
                                                    </Typography>
                                                </Typography>}
                                            value="330" />
                                    </Tabs>
                                    
                                </Grid>
                                <Grid
                                    item
                                    md={10}
                                    sm={9}
                                    xs={12}
                                    sx={{
                                        minHeight: '650px',
                                        borderLeft: 1,
                                        borderColor: {
                                            sm: colors.grey.main,
                                            xs: colors.grey.secondary
                                        }
                                    }}>
                                    <TabPanel value="90">
                                        {!settingsStyle ?
                                        <CardError
                                            title="Une erreur est survenue lors de la récupération des paramètres d'apparence"
                                            description="Veuillez réessayer ultérieurement" />

                                        :
                                        <FormPlateformeSettingsStyle
                                            settings={settingsStyle}
                                            send={(_) => setSettingsStyle(_)}
                                            handleError={(_) => {
                                                setSnackbarMessage(_);
                                                setIsSnackbarDisplayed(true);
                                            }} />}
                                    </TabPanel>
                                    <TabPanel value="100">
                                        {!settingsContact ?
                                        <CardError
                                            title="Une erreur est survenue lors de la récupération des paramètres de contact"
                                            description="Veuillez réessayer ultérieurement" />

                                        :
                                        <FormPlateformeSettingsContact
                                            settingsContact={settingsContact}
                                            send={(_) => setSettingsContact(_)}
                                            handleError={(_) => {
                                                setSnackbarMessage(_);
                                                setIsSnackbarDisplayed(true);
                                            }} />}
                                    </TabPanel>
                                    <TabPanel value="110">
                                        {!settingsContact ?
                                        <CardError
                                            title="Une erreur est survenue lors de la récupération des paramètres du support"
                                            description="Veuillez réessayer ultérieurement" />

                                        :
                                        <FormPlateformeSettingsSupport
                                            settingsContact={settingsContact}
                                            send={(_) => setSettingsContact(_)}
                                            handleError={(_) => {
                                                setSnackbarMessage(_);
                                                setIsSnackbarDisplayed(true);
                                            }} />}
                                    </TabPanel>

                                    <TabPanel value="170">
                                        {!settingsTemplateDocument ?
                                        <CardError
                                            title="Une erreur est survenue lors de la récupération des paramètres des documents"
                                            description="Veuillez réessayer ultérieurement" />

                                        :
                                        <FormPlateformeSettingsTemplateDocument
                                            settings={settingsTemplateDocument}
                                            send={(_) => setSettingsTemplateDocument(_)}
                                            handleError={(_) => {
                                                setSnackbarMessage(_);
                                                setIsSnackbarDisplayed(true);
                                            }} />}
                                    </TabPanel>
                                    <TabPanel value="180">
                                        {!settingsTemplateEmail ?
                                        <CardError
                                            title="Une erreur est survenue lors de la récupération des paramètres des mails"
                                            description="Veuillez réessayer ultérieurement" />

                                        :
                                        <FormPlateformeSettingsTemplateEmail
                                            settings={settingsTemplateEmail}
                                            send={(_) => setSettingsTemplateEmail(_)}
                                            handleError={(_) => {
                                                setSnackbarMessage(_);
                                                setIsSnackbarDisplayed(true);
                                            }} />}
                                    </TabPanel>

                                    <TabPanel value="200">
                                        {!settingsEstimation ?
                                        <CardError
                                            title="Une erreur est survenue lors de la récupération du paramétrage des devis"
                                            description="Veuillez réessayer ultérieurement" />

                                        :
                                        <FormPlateformeSettingsEstimation
                                            plateforme={plateforme}
                                            settings={settingsEstimation}
                                            send={(_) => setSettingsEstimation(_)}
                                            handleError={(_) => {
                                                setSnackbarMessage(_);
                                                setIsSnackbarDisplayed(true);
                                            }} />}
                                    </TabPanel>
                                    <TabPanel value="201">
                                        {!settingsDocument ?
                                        <CardError
                                            title="Une erreur est survenue lors de la récupération du paramétrage des pièces justificatives"
                                            description="Veuillez réessayer ultérieurement" />

                                        :
                                        <FormPlateformeSettingsDocument
                                            settingsDocument={settingsDocument}
                                            send={(_) => setSettingsDocument(_)}
                                            handleError={(_) => {
                                                setSnackbarMessage(_);
                                                setIsSnackbarDisplayed(true);
                                            }} />}
                                    </TabPanel>
                                    <TabPanel value="210">
                                        {!settingsBanking ?
                                        <CardError
                                            title="Une erreur est survenue lors de la récupération des paramètres bancaires"
                                            description="Veuillez réessayer ultérieurement" />

                                        :
                                        <FormPlateformeSettingsBanking
                                            settingsBanking={settingsBanking}
                                            send={(_) => setSettingsBanking(_)}
                                            handleError={(_) => {
                                                setSnackbarMessage(_);
                                                setIsSnackbarDisplayed(true);
                                            }} />}
                                    </TabPanel>
                                    <TabPanel value="220">
                                        {!settingsFraud ?
                                        <CardError
                                            title="Une erreur est survenue lors de la récupération des paramètres de fraude"
                                            description="Veuillez réessayer ultérieurement" />

                                        :
                                        <FormPlateformeSettingsFraud
                                            plateforme={plateforme}
                                            settingsFraud={settingsFraud}
                                            send={(_) => setSettingsFraud(_)}
                                            handleError={(_) => {
                                                setSnackbarMessage(_);
                                                setIsSnackbarDisplayed(true);
                                            }} />}
                                    </TabPanel>
                                    <TabPanel value="300">
                                        {!settingsSubscription ?
                                        <CardError
                                            title="Une erreur est survenue lors de la récupération des paramètres de souscription"
                                            description="Veuillez réessayer ultérieurement" />

                                        :
                                        <FormPlateformeSettingsSubscription
                                            settingsSubscription= { settingsSubscription }
                                            send={(_) => setSettingsSubscription(_)}
                                            handleError={(_) => {
                                                setSnackbarMessage(_);
                                                setIsSnackbarDisplayed(true);
                                            }} />}
                                    </TabPanel>
                                    <TabPanel value="310">
                                        {!settingsSmtp ?
                                        <CardError
                                            title="Une erreur est survenue lors de la récupération des paramétres SMTP"
                                            description="Veuillez réessayer ultérieurement" />

                                        :
                                        <FormPlateformeSettingsSmtp
                                            settingsSmtp={settingsSmtp}
                                            send={(_) => setSettingsSmtp(_)}
                                            handleError={(_) => {
                                                setSnackbarMessage(_);
                                                setIsSnackbarDisplayed(true);
                                            }} />}
                                    </TabPanel>
                                    <TabPanel value="320">
                                        {!settingsTarification ?
                                        <CardError
                                            title="Une erreur est survenue lors de la récupération des paramètres de tarification"
                                            description="Veuillez réessayer ultérieurement" />

                                        :
                                        <FormPlateformeSettingsTarification
                                            plateforme={plateforme}
                                            settingsTarification={settingsTarification}
                                            send={(_) => setSettingsTarification(_)}
                                            handleError={(_) => {
                                                setSnackbarMessage(_);
                                                setIsSnackbarDisplayed(true);
                                            }} />}
                                    </TabPanel>
                                    
                                    <TabPanel value="330">
                                        {!settingsWebhook ?
                                        <CardError
                                            title="Une erreur est survenue lors de la récupération des paramétres du Webhook"
                                            description="Veuillez réessayer ultérieurement" />

                                        :
                                        <FormPlateformeSettingsWebhook
                                            settingsWebhook={settingsWebhook}
                                            send={(_) => setSettingsWebhook(_)}
                                            handleError={(_) => {
                                                setSnackbarMessage(_);
                                                setIsSnackbarDisplayed(true);
                                            }} />}
                                    </TabPanel>
                                </Grid>
                            </Grid>
                        </TabContext>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                </Grid>
                </>}
            </Grid>

            <Snackbar
                sx={{
                    maxWidth: '1200px',
                    width: {
                        sm: '100%'
                    },
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarDisplayed}
                autoHideDuration={3000}
                onClose={handleSnackbarClose} >
                <Alert
                    sx={{
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                        boxShadow: 3
                    }}
                    severity='error'
                    iconMapping={{
                        error: <CloseFullIcon color='white' bgColor={colors.red.main} />,
                    }} >
                    <Typography fontWeight={500} >{snackbarMessage}</Typography>
                </Alert>
            </Snackbar>
        </Box>
    </>)
}
