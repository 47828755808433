import { useEffect, useRef, useState } from "react";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";

import { FormOfferHeader } from "../../components/Form/FormOfferHeader";
import { OfferDetails } from "../../components/Offer/OfferDetails";

import { IContractNeeds, getFormuleRecommendedId } from "../../model/ContractNeeds.model";
import { IFormule, IFormuleDetails } from "../../model/Formule.model";
import useOnScreen from "../../utils/hooks/useOnScreen";
import { IconButton, Tooltip } from "@mui/material";
import { ChevronUpIcon } from "../Icon/ChevronUpIcon";


interface Props {
    plateformStyle: any;
    formulesDetails: IFormuleDetails[];
    formuleRecommended?: number;
    contractNeeds: IContractNeeds;
    enableEstimation?: boolean;
    openDialogDevis: () => void
    sendFormule: (formule: IFormule) => void;
}


export const FormulaSelectContent = (props: Props) => {

    const theme = useTheme();
    const plateformStyle: any = props.plateformStyle;

    const screenSizeUpMD = useMediaQuery(theme.breakpoints.up('md'));
    const screenSizeUpLG = useMediaQuery(theme.breakpoints.up('lg'));
    const nbFormuleDisplayed = screenSizeUpLG ? 3 : screenSizeUpMD ? 2 : 1;

    const refHeader = useRef<any>(null)
    const refHeaderFlag = useRef<any>(null)
    const isHeaderVisible = useOnScreen(refHeader, {threshold: 0.5})
    const isHeaderFlagVisible = useOnScreen(refHeaderFlag, {threshold: 1})
    const [isHeaderSticky, setIsHeaderSticky] = useState<boolean>(false)

    const [isOfferDetailDisplayed, setIsOfferDetailDisplayed] = useState<boolean>(false);

    const getFormuleLeft = () => {
        if (!props.formulesDetails || formuleSelected === undefined)
            return;

        return props.formulesDetails[formuleSelected - 1];
    }

    const getFormuleCenter = () => {
        if (!props.formulesDetails || formuleSelected === undefined)
            return;

        return props.formulesDetails[formuleSelected];
    }

    const getFormuleRight = () => {
        if (!props.formulesDetails || formuleSelected === undefined)
            return;

        return props.formulesDetails[formuleSelected + 1];
    }

    const getFormulePrevious = () => {
        if (!props.formulesDetails || formuleSelected === undefined)
            return;

        switch (nbFormuleDisplayed) {
            case 1:
            case 2:
                if (formuleSelected === 0)
                    return;
                return props.formulesDetails[formuleSelected - 1];

            default:
            case 3:
                if (formuleSelected === 0)
                    return;
                else if (formuleSelected === 1)
                    return {formule: {formuleLibelle: undefined}, color: plateformStyle.colors.black.main} as IFormuleDetails;
                break;
        }

        return props.formulesDetails[formuleSelected - 2];
    }

    const getFormuleNext = () => {
        if (!props.formulesDetails || formuleSelected === undefined)
            return;

        switch (nbFormuleDisplayed) {
            case 1:
                if (formuleSelected === props.formulesDetails.length - 1)
                    return;
                return props.formulesDetails[formuleSelected + 1];

            default:
            case 2:
            case 3:
                if (formuleSelected === props.formulesDetails.length - 1)
                    return;
                else if (formuleSelected === props.formulesDetails.length - 2)
                    return {formule: {formuleLibelle: undefined}, color: plateformStyle.colors.black.main} as IFormuleDetails;
                break;
        }

        return props.formulesDetails[formuleSelected + 2];
    }

    const selectFormule = (index?: number) => {
        if (!props.formulesDetails || props.formulesDetails.length === 0)
            return;

        if (index === undefined || index < 0)
            setFormuleSelected(props.formulesDetails.findIndex(_ => _.isRecommended) !== -1 ? props.formulesDetails.findIndex(_ => _.isRecommended) : Math.round(props.formulesDetails.length / 2));

        // else if (index === 0 && nbFormuleDisplayed === 3)
        //     setFormuleSelected(index + 1);

        // else if (index === props.formulesDetails.length - 1 && nbFormuleDisplayed >= 3)
        //     setFormuleSelected(index - 1);

        else
            setFormuleSelected(index)
    }

    const [formuleCurrent, setFormuleCurrent] = useState<number|undefined>();
    const [formuleSelected, setFormuleSelected] = useState<number|undefined>();
    const [formuleDetailSelected, setFormuleDetailSelected] = useState<IFormuleDetails|undefined>();
    const [formuleLeft, setFormuleLeft] = useState<IFormuleDetails|undefined>(getFormuleLeft());
    const [formuleCenter, setFormuleCenter] = useState<IFormuleDetails|undefined>(getFormuleCenter());
    const [formuleRight, setFormuleRight] = useState<IFormuleDetails|undefined>(getFormuleRight());
    const [formulePrevious, setFormulePrevious] = useState<IFormuleDetails|undefined>(getFormulePrevious());
    const [formuleNext, setFormuleNext] = useState<IFormuleDetails|undefined>(getFormuleNext());

    useEffect(() => {
        if (formuleSelected === undefined) selectFormule(undefined)

        setFormuleLeft(getFormuleLeft());
        setFormuleCenter(getFormuleCenter());
        setFormuleRight(getFormuleRight());
        setFormulePrevious(getFormulePrevious());
        setFormuleNext(getFormuleNext());
    }, [props.formulesDetails])


    useEffect(() => {
        if (formuleSelected === undefined) return

        setFormuleLeft(getFormuleLeft());
        setFormuleCenter(getFormuleCenter());
        setFormuleRight(getFormuleRight());
        setFormulePrevious(getFormulePrevious());
        setFormuleNext(getFormuleNext());
    }, [formuleSelected,
        nbFormuleDisplayed])


    useEffect(() => {
        if (props.contractNeeds.formule && props.formulesDetails.length > 0 && formuleCurrent === undefined) {
            let current = props.formulesDetails?.findIndex(_ => _.formule.identifiant === parseInt(props.contractNeeds?.formule?.idHenner || "0"))

            setFormuleCurrent(current)
            setFormuleDetailSelected(props.formulesDetails[current])
            setIsOfferDetailDisplayed(true)
            selectFormule(current)

            return;
        }

        let formuleRecommended = getFormuleRecommendedId(props.contractNeeds);
        let formuleRecommendedId = props.formulesDetails?.findIndex(_ => _.formule.identifiant === formuleRecommended)

        selectFormule(formuleRecommendedId)

        if (formuleSelected && formuleRecommended !== undefined) {
            setIsOfferDetailDisplayed(true)
            setFormuleDetailSelected(props.formulesDetails[formuleRecommendedId])
        }
    }, [props.contractNeeds])


    useEffect(() => {
        if (isHeaderFlagVisible)
            setIsHeaderSticky(false)
        else if (!isHeaderVisible)
            setIsHeaderSticky(true)

    }, [isHeaderFlagVisible, isHeaderVisible])


    const sendFormule = (formule: IFormule) => {
        props.sendFormule(formule)
    }

    const scrollTo = (ref: any) => {
        if (ref.current)
            (ref.current as any).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
    }


    return (
        <Grid
            container
            justifyContent='flex-end'
            spacing={2}
            sx={{
                position: "relative"
            }}>

            <Grid
                item
                xs={12}
                ref={refHeaderFlag}>
            </Grid>

            <Grid
                ref={refHeader}
                item
                xs={12}
                sx={{
                    zIndex: 100,
                    position: isHeaderSticky ? "sticky" : "relative",
                    top: {
                        lg: isHeaderSticky ? 60 : 0,
                        md: isHeaderSticky ? 55 : 0,
                        xs: isHeaderSticky ? 45 : 0,
                    },
                    transition: isHeaderSticky ? "top 200ms ease" : "none",
                    mt: !isHeaderSticky ? 1 : 0
                }}>
                {formuleCenter && formuleSelected !== undefined &&
                <FormOfferHeader
                    plateformStyle={plateformStyle}
                    formulesDetailsLength={props.formulesDetails.length}
                    formuleCenter={formuleCenter}
                    formuleLeft={formuleLeft}
                    formuleRight={formuleRight}
                    formulePrevious={formulePrevious}
                    formuleNext={formuleNext}
                    isDetailDisplayed={isOfferDetailDisplayed}
                    isMinified={isHeaderSticky}
                    selectFormule={(_) => sendFormule(_.formule)}
                    goToPrevious={() => selectFormule(formuleSelected - 1)}
                    goToNext={() => selectFormule(formuleSelected + 1)}
                    toggleDetail={(formule) => {
                        setIsOfferDetailDisplayed(true)
                        setFormuleDetailSelected(formule)
                        if (formule === formuleLeft)
                            selectFormule(formuleSelected - 1)
                        else if (formule === formuleRight)
                            selectFormule(formuleSelected + 1)
                    }}
                />}
            </Grid>

            {/* Formules Details. */}
            <Grid
                xs={12}
                item >
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    spacing={{
                        sm: 2
                    }}>
                    {formuleCenter && formuleSelected !== undefined &&
                    <Grid
                        xs={12}
                        item >
                        <Collapse
                            in={isOfferDetailDisplayed}
                            timeout={500}>
                            <OfferDetails
                                plateformStyle={plateformStyle}
                                codeOffer={props.contractNeeds.codeOffre}
                                formulesDetailsLength={props.formulesDetails.length}
                                formuleCenterId={formuleCenter.formule.identifiant}
                                formuleLeftId={formuleLeft?.formule.identifiant}
                                formuleRightId={formuleRight?.formule.identifiant}
                                formuleSelectedId={formuleCenter?.formule.identifiant}
                                formuleRecommendedId={props.formulesDetails.find(_ => _.isRecommended)?.formule.identifiant}
                            />
                        </Collapse>
                    </Grid>}

                    {formuleCenter &&
                    <Grid
                        item
                        xs={12}
                        sx={{
                            position: "sticky",
                            bottom: '16px',
                            textAlign: 'center'
                        }}>
                        <Grid
                            container
                            justifyContent='center'
                            alignItems='center'
                            spacing={{
                                sm: 2,
                                xs: 1.5
                            }}
                            sx={{
                                width: '100%'
                            }}>

                            {props.enableEstimation !== false &&
                            <Grid
                                item
                                xs="auto"
                                sx={{
                                    display: {
                                        md: isHeaderSticky ? 'inline-flex' : 'none',
                                        xs: 'none'
                                    }
                                }}>
                                <Button
                                    id="bouton_obtenirdevis_choixoffre_bas"
                                    variant='contained'
                                    color='inherit'
                                    sx={{
                                        py: 0.75,
                                        px: 2,
                                        color: 'white',
                                        backgroundColor: plateformStyle.colors.black.main,
                                        transition: "transform 400ms",
                                        ':hover': {
                                            transform: "translateY(-6px)",
                                            backgroundColor: 'black'
                                        }
                                    }}
                                    onClick={() => {
                                        if (props.openDialogDevis !== undefined) props.openDialogDevis()
                                    }}>
                                    Obtenir un devis
                                </Button>
                            </Grid>}

                            <Grid
                                item
                                xs="auto"
                                sx={{
                                    display: {
                                        md: 'inline-flex',
                                        xs: !isHeaderSticky ? 'inline-flex' : 'none'
                                    }
                                }}>
                                <Button
                                    id="bouton_detailgaranties_choixoffre"
                                    variant='outlined'
                                    color='inherit'
                                    sx={{
                                        py: 0.5,
                                        border: 2,
                                        borderColor: plateformStyle.colors.black.main,
                                        color: plateformStyle.colors.black.main,
                                        backgroundColor: "white",
                                        transition: "transform 400ms",
                                        ':hover': {
                                            transform: "translateY(-6px)",
                                            backgroundColor: "white",
                                            borderColor: plateformStyle.colors.black.main,
                                        }
                                    }}
                                    onClick={() => {
                                        setIsOfferDetailDisplayed((prev) => !prev)
                                        if (isHeaderSticky) scrollTo(refHeaderFlag)
                                    }}>
                                    {isOfferDetailDisplayed ? "Masquer" : "Afficher"} les garanties
                                </Button>
                            </Grid>

                            {isHeaderSticky &&
                            <Grid
                                item
                                sm="auto"
                                xs={12}
                                order={{
                                    sm: 1,
                                    xs: 0
                                }}>
                                <Tooltip
                                    title="Remonter aux formules"
                                    placement="top">
                                    <IconButton
                                        sx={{
                                            p: 0,
                                            transition: "transform 400ms",
                                            ':hover': {
                                                transform: "translateY(-6px)"
                                            }
                                        }}
                                        onClick={() => {
                                            scrollTo(refHeaderFlag)
                                        }}>
                                        <ChevronUpIcon bgColor="white" color={plateformStyle.colors.black.main} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>}

                            <Grid
                                item
                                sm="auto"
                                xs
                                sx={{
                                    display: {
                                        md: 'none',
                                        xs: isHeaderSticky ? "inline-flex" : 'none'
                                    }
                                }}>
                                <Button
                                    variant='contained'
                                    color='success'
                                    className="Button_Goto_Step1_ChoixOffre"
                                    fullWidth
                                    sx={{
                                        whiteSpace: "nowrap",
                                        px: 5,
                                        py: 1.25,
                                        color: 'white',
                                        backgroundColor: plateformStyle.colors.black.main,
                                        transition: "transform 400ms",
                                        ':hover': {
                                            transform: "translateY(-6px)",
                                            backgroundColor: 'black'
                                        }
                                    }}
                                    onClick={() => sendFormule(formuleCenter?.formule)} >
                                    Choisir cette offre
                                </Button>
                            </Grid>

                            {isHeaderSticky &&
                             props.enableEstimation !== false &&
                            <Grid
                                item
                                sm="auto"
                                xs
                                sx={{
                                    display: {
                                        md: 'none',
                                        xs: isHeaderSticky ? "inline-flex" : 'none'
                                    }
                                }}>
                                <Button
                                    id="bouton_obtenirdevis_choixoffre_bas"
                                    variant='outlined'
                                    color='inherit'
                                    fullWidth
                                    sx={{
                                        whiteSpace: "nowrap",
                                        px: 5,
                                        py: 1,
                                        border: 2,
                                        borderColor: plateformStyle.colors.black.main,
                                        color: plateformStyle.colors.black.main,
                                        backgroundColor: "white",
                                        transition: "transform 400ms",
                                        ':hover': {
                                            transform: "translateY(-6px)",
                                            backgroundColor: "white",
                                            borderColor: plateformStyle.colors.black.main,
                                        }
                                    }}
                                    onClick={() => {
                                        if (props.openDialogDevis !== undefined) props.openDialogDevis()
                                    }}>
                                    Obtenir un devis
                                </Button>
                            </Grid>}
                        </Grid>
                    </Grid>}
                </Grid>
            </Grid>
        </Grid>
    )
}
