export const colors = {
    black: {
        main: '#212529',
        secondary: '#6C757D'
    },
    grey: {
        main: '#DFE2E1',
        secondary: '#F7F7F7'
    },
    green: {
        main: '#14BD96',
        secondary: '#49c2a6'
    },
    turquoise: {
        main: '#0DCAF0'
    },
    blue: {
        main: '#2F6BEE',
        secondary: '#C5DDF4'
    },
    purple: {
        main: '#7F68D5',
        secondary: '#6610F2'
    },
    pink: {
        main: '#D63384'
    },
    red: {
        main: '#EF5350'
    },
    orange: {
        main: '#FF6944',
        secondary: '#FEAA16',
        light: '#FFB3A1'
    }
}
