import { IForm, IFormPatchPlateform } from "../model/Form.model"
import { IFormContext } from "../model/FormContext.model"


const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const getFormContext = (uuid: string) : Promise<IFormContext> => {

    return fetch(`${apiUrl}/form/${uuid}/context`,
    {
        method: "GET",

    }).then(_ => _.json())
}


export const createForm = (form: IForm) : Promise<IForm> => {

    return fetch(`${apiUrl}/form`,
    {
        method: "POST",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(form)

    }).then(_ => _.json())
}


export const modifyForm = (form: IForm) : Promise<IForm> => {

    return fetch(`${apiUrl}/form`,
    {
        method: "PUT",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(form)

    }).then(_ => _.json())
}


export const patchFormPlateform = (uuid: string, payload: IFormPatchPlateform) : Promise<IForm> => {

    return fetch(`${apiUrl}/form/${uuid}/plateform`,
    {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(payload)
    }).then(_ => _.json())
}
