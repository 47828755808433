import { useEffect, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

import { DialogBeneficiaries } from "../../components/Dialog/DialogBeneficiaries";
import { DialogNeeds } from "../../components/Dialog/DialogNeeds";
import { FormHeader } from "../../components/Form/FormHeader";
import { FormulaSelectContent } from "../../components/Content/FormulaSelectContent";
import { PageContentLayout } from "../../components/Layout/PageContentLayout";

import { modifyContractNeeds } from "../../api/ContractNeeds.api";
import { getRefFormuleByHennerId } from "../../api/RefFormule.api";

import { CallbackStatus, CallbackType, ICallback } from "../../model/Callback.model";
import { IContractNeeds } from "../../model/ContractNeeds.model";
import { IForm } from "../../model/Form.model";
import { IFormule, IFormuleDetails } from "../../model/Formule.model";
import { IPerson } from "../../model/Person.model";
import { IPromotion } from "../../model/Promotion.model";
import { CardPromotion } from "../../components/Card/CardPromotion";
import { DialogPromotions } from "../../components/Dialog/DialogPromotions";
import { DialogDevis } from "../../components/Dialog/DialogDevis";
import { IPlateforme } from "../../model";

interface Props {
    plateformStyle: any;
    formulesDetails?: IFormuleDetails[];
    form?: IForm;
    contractNeeds: IContractNeeds;
    subscriber: IPerson;
    conjoint?: IPerson;
    children?: IPerson[];
    promotions?: IPromotion[];
    enableEstimation?: boolean;
    sendSubscriber: (person: IPerson) => void;
    sendConjoint: (conjoint?: IPerson) => void;
    sendChildren: (children?: IPerson[]) => void;
    sendFormule: (formule: IFormule) => void;
    sendContractNeeds: (contractNeeds: IContractNeeds) => void;
    goToPreviousFlag: boolean;
    goToStepBenef?: () => void;
    goToNext: () => void;
    goToPrevious: () => void;
}


export const FormOfferSelect = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;

    const theme = useTheme()
    const screenSizeUpLG = useMediaQuery(theme.breakpoints.up('lg'));

    const [isDialogPromotionsOpened, setIsDialogPromotionsOpened] = useState<boolean>(false);
    const [isDialogNeedsOpened, setIsDialogNeedsOpened] = useState<boolean>(false);
    const [isDialogBeneficiariesOpened, setIsDialogBeneficiariesOpened] = useState<boolean>(false);
    const [isDialogDevisOpened, setIsDialogDevisOpened] = useState<boolean>(false);
    const [callback, setCallback] = useState<ICallback>();

    const selectFormule = (formule: IFormule) => {

        getRefFormuleByHennerId(formule.identifiant)
        .then(response => {
            let contractNeeds: IContractNeeds = props.contractNeeds;

            contractNeeds.formule = response;

            return modifyContractNeeds(contractNeeds);
        })
        .then(response => {
            props.sendContractNeeds(response);
            props.sendFormule(formule);
        })

        props.goToNext();
    }

    useEffect(() => {
        if (!props.goToPreviousFlag)
            return;

        props.goToPrevious();
    }, [props.goToPreviousFlag])

    useEffect(() => {
        setCallback({
            type: CallbackType.CALLBACK,
            status: CallbackStatus.PENDING,
            form: props.form,
            senderLastname: props.subscriber?.lastname,
            senderFirstname: props.subscriber?.firstname,
            senderMail: props.subscriber?.email,
            senderPhone: props.subscriber?.phoneMobile,
            subject: "Besoin spécifique"
        })
    }, [props.form, props.subscriber])

    return (
        <PageContentLayout
            header={
                <FormHeader label="Votre formule" />
            }
            content={
                <Grid
                    container
                    justifyContent='flex-start'
                    alignItems="center"
                    spacing={2}>

                    {props.promotions && props.promotions.length > 0 &&
                    <Grid
                        item
                        xs={12}>
                        <Grid
                            container
                            justifyContent='flex-start'
                            alignItems="stretch"
                            spacing={{
                                sm: 2,
                                xs: 1
                            }}>
                            <>
                            {props.promotions.slice(0, screenSizeUpLG ? 2 : 1).map(_ => {
                                return (
                                    <Grid
                                        item
                                        lg={props.promotions && props.promotions.length > 1 ? true : 6}
                                        md={6}
                                        xs
                                        sx={{
                                            maxWidth: "600px",
                                        }}>
                                        <CardPromotion
                                            plateformStyle={props.plateformStyle}
                                            promotion={_} />
                                    </Grid>
                                )
                            })}

                            {props.promotions.length > (screenSizeUpLG ? 2 : 1) &&
                            <Grid
                                item
                                xs="auto"
                                sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}>
                                <Button
                                    variant='outlined'
                                    color='inherit'
                                    sx={{
                                        py: 0.5,
                                        border: 2,
                                        borderColor: plateformStyle.colors.black.main,
                                        color: plateformStyle.colors.black.main,
                                        backgroundColor: "white",
                                        transition: "transform 400ms",
                                        ':hover': {
                                            transform: "translateY(-6px)",
                                            borderColor: plateformStyle.colors.black.main,
                                        }
                                    }}
                                    onClick={() => setIsDialogPromotionsOpened(true)}>
                                    <Typography
                                        variant="button"
                                        sx={{
                                            display: {
                                                sm: "inline",
                                                xs: "none"
                                            },
                                            textTransform: "none"
                                        }}>
                                        Voir tout
                                    </Typography>
                                    <Typography
                                        variant="button"
                                        sx={{
                                            display: {
                                                sm: "none",
                                                xs: "inline"
                                            },
                                            textTransform: "none"
                                        }}>
                                        +{props.promotions.length - (screenSizeUpLG ? 2 : 1)}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        fontWeight={700}
                                        sx={{
                                            display: {
                                                sm: "inline",
                                                xs: "none"
                                            },
                                            fontSize: "16px !important",
                                            px: 1,
                                            ml: {
                                                sm: 1,
                                                xs: 0
                                            },
                                            background: plateformStyle.colors.black.main,
                                            color: "white",
                                            borderRadius: 50
                                        }}>
                                        + {props.promotions.length - (screenSizeUpLG ? 2 : 1)}
                                    </Typography>
                                </Button>
                            </Grid>}

                            <DialogPromotions
                                plateformStyle={plateformStyle}
                                isOpened={isDialogPromotionsOpened}
                                promotions={props.promotions}
                                handleClose={() => {setIsDialogPromotionsOpened(false)}} />
                            </>
                        </Grid>
                    </Grid>}

                    {props.form?.contract?.id &&
                     props.enableEstimation !== false &&
                    <Grid
                        item
                        xs="auto">
                        <Button
                            id="bouton_obtenirdevis_choixoffre_haut"
                            variant='outlined'
                            color='inherit'
                            sx={{
                                py: 0.5,
                                border: 2,
                                borderColor: plateformStyle.colors.black.main,
                                color: plateformStyle.colors.black.main,
                                backgroundColor: "white",
                                transition: "transform 400ms",
                                ':hover': {
                                    transform: "translateY(-6px)",
                                    borderColor: plateformStyle.colors.black.main,
                                }
                            }}
                            onClick={() => setIsDialogDevisOpened(true)}>
                            Obtenir un devis
                        </Button>

                        <DialogDevis
                            plateformStyle={plateformStyle}
                            isOpened={isDialogDevisOpened}
                            contractId={props.form?.contract?.id}
                            offerCode={props.contractNeeds?.codeOffre}
                            formulesSelected={props.contractNeeds.formule ? [parseInt(props.contractNeeds.formule.idHenner)] : [props.formulesDetails?.find(_ => _.isRecommended)?.formule.identifiant || 0]}
                            formules={props.formulesDetails}
                            subscriber={props.subscriber}
                            isConsentContactGsmc={props.form.isConsentContactGsmc}
                            isConsentContactPartner={props.form.isConsentContactPartner}
                            sendSubscriber={(_) => props.sendSubscriber(_)}
                            handleClose={() => setIsDialogDevisOpened(false)} />
                    </Grid>}

                    <Grid
                        item
                        xs="auto">
                        <Button
                            variant='outlined'
                            color='inherit'
                            endIcon={
                                <Typography
                                    variant="caption"
                                    fontWeight={700}
                                    sx={{
                                        fontSize: "16px !important",
                                        px: 1,
                                        background: plateformStyle.colors.black.main,
                                        color: "white",
                                        borderRadius: 50
                                    }}>
                                    {(props.children?.length || 0) + (props.conjoint ? 1 : 0)}
                                </Typography>}
                            sx={{
                                py: 0.5,
                                border: 2,
                                borderColor: plateformStyle.colors.black.main,
                                color: plateformStyle.colors.black.main,
                                backgroundColor: "white",
                                transition: "transform 400ms",
                                ':hover': {
                                    transform: "translateY(-6px)",
                                    borderColor: plateformStyle.colors.black.main,
                                }
                            }}
                            onClick={() => {
                                {/* TODO : don't show if props.conjoint and props.children (check person.id) */}
                                if (props.goToStepBenef !== undefined)
                                    props.goToStepBenef()
                                else
                                    setIsDialogBeneficiariesOpened(true)
                            }}>
                            Mes bénéficiaires
                        </Button>

                        <DialogBeneficiaries
                            plateformStyle={plateformStyle}
                            isOpened={isDialogBeneficiariesOpened}
                            subscriber={props.subscriber}
                            conjoint={props.conjoint}
                            children={props.children}
                            sendConjoint={props.sendConjoint}
                            sendChildren={props.sendChildren}
                            handleClose={() => setIsDialogBeneficiariesOpened(false)} />
                    </Grid>

                    <Grid
                        item
                        xs="auto">
                        <Button
                            id="bouton_affinerbesoins_choixoffre"
                            variant='outlined'
                            color='inherit'
                            sx={{
                                py: 0.5,
                                border: 2,
                                borderColor: plateformStyle.colors.black.main,
                                color: plateformStyle.colors.black.main,
                                backgroundColor: "white",
                                transition: "transform 400ms",
                                ':hover': {
                                    transform: "translateY(-6px)",
                                    borderColor: plateformStyle.colors.black.main,
                                }
                            }}
                            onClick={() => setIsDialogNeedsOpened(true)}>
                            Affiner mes besoins
                        </Button>

                        <DialogNeeds
                            plateformStyle={plateformStyle}
                            isOpened={isDialogNeedsOpened}
                            contractNeeds={props.contractNeeds}
                            formules={props.formulesDetails}
                            callback={callback}
                            sendContractNeeds={props.sendContractNeeds}
                            sendCallback={(_) => props.sendSubscriber({...props.subscriber, lastname: _.senderLastname, firstname: _.senderFirstname, phoneMobile: _.senderPhone})}
                            handleClose={() => setIsDialogNeedsOpened(false)} />
                    </Grid>

                    <Grid
                        xs={12}
                        item >
                        {!props.formulesDetails ?
                        <Card
                            sx={{
                                textAlign: 'center'
                            }} >
                            <LinearProgress
                                value={100}
                                color='primary' />

                            <Grid
                                container
                                alignItems='center'
                                sx={{
                                    height: '150px'
                                }}>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        my: 3,
                                        mx: 1
                                    }}>
                                    <Typography
                                        component='div'
                                        variant='h2' >
                                        Chargement des formules en cours...
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                        :
                        props.formulesDetails.length === 0 ?
                        <Card
                            sx={{
                                textAlign: 'center'
                            }} >

                            <Grid
                                container
                                alignItems='center'
                                sx={{
                                    height: '100%'
                                }} >
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        my: 3,
                                        mx: 1
                                    }}>
                                    <Typography
                                        component='div'
                                        variant='h2' >
                                        Une erreur est survenue, veuillez réessayer plus tard
                                    </Typography>
                                    <Typography
                                        component='div'
                                        variant='caption' >
                                        Récupération des formules impossible
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>

                        :
                        <FormulaSelectContent
                            plateformStyle={plateformStyle}
                            formulesDetails={props.formulesDetails}
                            contractNeeds={props.contractNeeds}
                            openDialogDevis={() => setIsDialogDevisOpened(true)}
                            enableEstimation={props.enableEstimation}
                            sendFormule={selectFormule} />
                        }
                    </Grid>
                </Grid>
            } />
    )
}
